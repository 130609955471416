<template>
    <div class="declaring-block-main-aedecp optiscroll stepTwo">
        <div class="declaring-block-in-aedecp">
            <div class="common-title-main-aehp common-title-left-aehp">
                <h2>Déclarant</h2>
            </div>
            <div class="declaring-form-main-aedecp">
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp errorLabel">
                                Nom ou Raison sociale
                            </div>
                            <div class="defa-form-field-Tbox-aedecp">
                                <input v-model="declarant.name" type="text" @change="(e) => remove(e.target)" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">
                                Adresse du déclarant
                            </div>
                            <div class="default-note-text-aedecp default-note-text2-aedecp">
                                <p>Nous avons besoin de vos coordonnées pour vous faire parvenir des informations
                                    nécessaires au traitement de votre sinistre.</p>
                            </div>
                            <div class="default-sub-form-row1-aedecp">
                                <div
                                    class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp add-field1-aedecp">
                                    <div class="default-form-field1-Stit-aedecp">Rue</div>
                                    <div class="defa-form-field-Tbox-aedecp" style="position: relative">
                                        <input v-model="declarant.street" @change="(e) => remove(e.target)"
                                            @input="fetchPredictions" @keydown="onKeyDown" type="text" />
                                        <ul v-if="predictions.length" style="
                        position: absolute;
                        margin-top: 10px;
                        width: 100%;
                        background-color: white;
                        z-index: 50;
                        font-size: 16px;
                        box-shadow: var(
                          0 20px 25px -5px rgb(0 0 0 / 0.1),
                          0 8px 10px -6px rgb(0 0 0 / 0.1)
                        );
                        border: solid 2px #e4e8f4;
                        color: #434756;
                        border-radius: 4px;
                      ">
                                            <li v-for="prediction in predictions" :key="prediction.place_id"
                                                @click="selectAddress(prediction.place_id)"
                                                style="padding: 8px; cursor: pointer">
                                                {{ prediction.description }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="default-sub-form-row1-aedecp">
                                <div
                                    class="col-lg-7 col-md-7 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W65-aedecp">
                                    <div class="default-form-field1-Stit-aedecp">Ville</div>
                                    <div class="defa-form-field-Tbox-aedecp">
                                        <input v-model="declarant.city" @change="(e) => remove(e.target)" type="text" />
                                    </div>
                                </div>
                                <div
                                    class="col-lg-5 col-md-5 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W35-aedecp">
                                    <div class="default-form-field1-Stit-aedecp">Code postal</div>
                                    <div class="defa-form-field-Tbox-aedecp">
                                        <input type="text" v-model="declarant.zipcode"
                                            @change="(e) => remove(e.target)" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-5 col-md-5 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W35-aedecp">
                            <div class="default-form-field1-tit-aedecp">Téléphone</div>
                            <div class="defa-form-field-Tbox-aedecp">
                                <input type="tel" v-model="declarant.phone_number" @change="(e) => remove(e.target)" />
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W65-aedecp">
                            <div class="default-form-field1-tit-aedecp">Email</div>
                            <div class="defa-form-field-Tbox-aedecp">
                                <input type="email" v-model="declarant.email" @change="(e) => remove(e.target)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="default-form-btn-bar-aedecp">
                <div class="default-form-btn-aedecp">
                    <a href="#" @click="previousStep" class="btn default-btn-aehp default-Bborder-btn-aehp">Etape
                        précédente</a>
                </div>
                <div class="default-form-btn-aedecp">
                    <a href="#" v-on:click="nextStep" class="btn default-btn-aehp">Continuer</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import $ from "jquery";
import { debounce } from "lodash-es";

export default {
    name: "StepTwo",
    props: ["type"],
    data() {
        return {
            declarant: {
                name: "",
                street: "",
                city: "",
                zipcode: "",
                phone_number: "",
                email: "",
            },
            predictions: [],
            autocompleteService: null,
            selectedIndex: -1,
        };
    },
    computed: mapState({
        storeDeclarant: (state) => state.createSinistre.declarant,
    }),
    beforeMount() {
        this.declarant = { ...this.storeDeclarant };
    },
    mounted() {
        if (window.google) {
            // eslint-disable-next-line no-undef
            this.autocompleteService = new google.maps.places.AutocompleteService();
        }
    },
    methods: {
        ...mapActions("createSinistre", ["addDeclarant"]),
        previousStep() {
            this.addDeclarant(this.declarant);
            this.$emit("click", { action: "StepOne" });
        },
        nextStep() {
            if (this.type === "assured") {
                let numberInputTwo = 0;
                let numberGoodInputTwo = 0;
                $(".stepTwo input").each(function () {
                    numberInputTwo++;
                    if (!$(this).val()) {
                        $(this).addClass("errorInput");
                    } else {
                        numberGoodInputTwo++;
                        $(this).removeClass("errorInput");
                    }
                });
                if (numberInputTwo === numberGoodInputTwo) {
                    this.addDeclarant(this.declarant);
                    this.$emit("click", { action: "StepThree" });
                    console.log("RESET");
                    numberInputTwo = 0;
                    numberGoodInputTwo = 0;
                } else {
                    const firstErrorInput = document.querySelector(".errorInput");
                    if (firstErrorInput) {
                        firstErrorInput.scrollIntoView({ behavior: "smooth" });
                    }
                }
            } else {
                this.addDeclarant(this.declarant);
                this.$emit("click", { action: "StepThree" });
            }
        },
        remove(e) {
            $(e).removeClass("errorInput");
        },
        fetchPredictions: debounce(function () {
            if (!this.declarant.street || !this.autocompleteService) {
                this.predictions = [];
                return;
            }

            this.autocompleteService.getPlacePredictions(
                {
                    input: this.declarant.street,
                    types: ["address"],
                    componentRestrictions: { country: "fr" },
                },
                (suggestions) => {
                    this.predictions = suggestions || [];
                    this.selectedIndex = -1;
                },
            );
        }, 200),
        selectAddress(placeId) {
            // eslint-disable-next-line no-undef
            const placesService = new google.maps.places.PlacesService(
                document.createElement("div"),
            );

            placesService.getDetails({ placeId }, (place) => {
                if (!place) return;

                this.declarant.street = "";
                this.declarant.city = "";
                this.declarant.zipcode = "";

                place.address_components.forEach((component) => {
                    if (component.types.includes("street_number")) {
                        this.declarant.street = component.long_name;
                    }
                    if (component.types.includes("route")) {
                        this.declarant.street += " " + component.long_name;
                        this.declarant.street = this.declarant.street.trim();
                    }
                    if (component.types.includes("locality")) {
                        this.declarant.city = component.long_name; // City
                    }
                    if (component.types.includes("postal_code")) {
                        this.declarant.zipcode = component.long_name; // Postal Code
                    }
                });

                this.predictions = [];
            });
        },
        onKeyDown(e) {
            if (e.key === "ArrowDown") {
                this.selectedIndex = (this.selectedIndex + 1) % this.predictions.length;
            } else if (e.key === "ArrowUp") {
                this.selectedIndex =
                    this.selectedIndex <= 0
                        ? this.predictions.length - 1
                        : this.selectedIndex - 1;
            } else if (e.key === "Enter" && this.selectedIndex !== -1) {
                this.selectAddress(this.predictions[this.selectedIndex].place_id);
            }
        },
    },
};
</script>
<style scoped>
.errorInput {
    border: solid 2px #f03737;
}
</style>
