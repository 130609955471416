<template>
    <div class="wrapper">
        <!-- CONTAIN_START -->
        <section id="contain">
            <div class="sidebar-main-aedecp sidebar-main-aehp">
                <div class="logo-main-aedecp"><a href="#"><img alt="" src="~@/assets/images/logo_main_white_hp.svg"></a>
                </div>
                <div class="sidebar-content-aedecp optiscroll">
                    <div class="sidebar-content-in-aedecp">
                        <div class="menu-main-aehp">
                            <ul>
                                <li class="active" v-on:click="this.$router.push('/assure/dashboard')">
                                    <!-- Add Class "active" -->
                                    <a href="#">
                                        <div class="menu-main-icon-aehp">
                                            <i class="fa-regular fa-house-crack"></i>
                                        </div>
                                        <div class="menu-main-text-aehp">Mon Sinistre</div>
                                    </a>
                                </li>
                                <li>
                                    <a v-if="iban" href="#" v-on:click="this.$router.push('/assure/dashboard/iban')">
                                        <div class="menu-main-icon-aehp">
                                            <i class="fa-regular fa-bank"></i>
                                        </div>
                                        <div class="menu-main-text-aehp">Mon IBAN</div>
                                    </a>
                                </li>
                                <li v-on:click="this.$router.push('/assure/dashboard/fichiers')">
                                    <a href="#">
                                        <div class="menu-main-icon-aehp">
                                            <i class="fa-regular fa-copy"></i>
                                        </div>
                                        <div class="menu-main-text-aehp">Mes Fichiers</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="declaration-close-aedecp"><a href="#" v-on:click="logOut"><i
                            class="fa-regular fa-sign-out"></i>Se
                        déconnecter</a></div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 content-main-aedecp content-main-aehp">
                <div class="top-bar-main-aedecp">
                    <div class="tbar-left-aedecp">
                        <div class="logo-main-mobile-aedecp"><a href="#"><img alt=""
                                    src="~@/assets/images/logo_main_hp.svg"></a>
                        </div>
                        <div class="tbar-user-info-main-aedecp">
                            <div class="tbar-user-icon-aedecp">
                                <div class="tbar-user-icon-in-aedecp">
                                    <a href="#"><img alt="" src="~@/assets/images/QBE_logo_aedecp.svg"></a>
                                </div>
                            </div>
                            <div class="tbar-user-text-aedecp">
                                <h2>QBE</h2>
                                <p v-on:click="openMessage()">Contrat n° <span>{{ sinister.contract_number_do }}</span>
                                </p>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="tbar-right-aedecp">
                        <!-- <div class="download-pdf-btn-aehp">
              <a href="#" class="btn default-btnH40-aehp default-Gray-border-btn-aehp"><i class="fa-regular fa-file-pdf"></i> <span>Télécharger le pdf</span></a>
            </div> -->
                        <div class="Message-btn-aehp">
                            <a id="openMessage" class="btn default-btnH40-aehp default-Bborder-btn-aehp"
                                href="javascript:void(0);" v-on:click="openMessage()"><i
                                    class="fa-regular fa-envelope-open"></i>
                                <span>Messagerie</span>
                                <div v-if="nbMessages > 0" class="Msg-btn-notification-aehp">{{ nbMessages }}</div>
                            </a>
                        </div>
                        <div class="mobile-menu-icon-aedecp">
                            <div class="menu-toggle-btn-aedecp">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="declaring-block-main-aedecp optiscroll">
                    <div class="declaring-block-in-aedecp">
                        <div class="home-title-main-aehp">
                            <div class="home-title-tag-main-aehp">SINISTRE</div>
                            <h1>{{ sinister.sinister_reference }}</h1>
                            <p>{{ declarant.name }}</p>
                        </div>
                        <div class="RIB-info-block-aghp tax-return-info-block-aghp">
                            <div class="RIB-info-left-aghp">
                                <div class="RIB-info-icon-aghp">
                                    <div class="RIB-info-icon-in-aghp">
                                        <i class="fa-regular fa-circle-info"></i>
                                    </div>
                                </div>
                                <div class="RIB-info-text-aghp">
                                    <p>Vous avez fait une erreur dans votre déclaration?</p>
                                    <h3>Contactez-nous directement via la messagerie</h3>
                                </div>
                            </div>
                            <div class="RIB-info-right-aghp">
                                <div class="RIB-info-btn-aghp">
                                    <a href="javascript:void(0);" @click="openMessage()"
                                        class="btn default-btnH40-aehp default-Bborder-btn-aehp">Nous
                                        contacter</a>
                                </div>
                            </div>
                        </div>
                        <div v-for="(notif, key) in attachments" :key="key" class="notications">
                            <div v-if="notif.type_attachment === 'bank'" class="RIB-info-block-aghp">
                                <div class="RIB-info-left-aghp">
                                    <div class="RIB-info-icon-aghp">
                                        <div class="RIB-info-icon-in-aghp">
                                            <i class="fa-regular fa-bank"></i>
                                        </div>
                                    </div>
                                    <div class="RIB-info-text-aghp">
                                        <p>Veuillez renseigner votre Relevé d'Identité Bancaire dans votre espace "Mon
                                            IBAN"</p>
                                    </div>
                                </div>
                                <div class="RIB-info-right-aghp"
                                    v-on:click="this.$router.push('/assure/dashboard/iban')">
                                    <div class="RIB-info-btn-aghp">
                                        <a class="btn default-btnH40-aehp default-Orng-border-btn-aehp" href="#">Aller
                                            renseigner mon
                                            IBAN</a>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="drop-file-info-block-aghp">
                                <div class="drop-file-info-left-aghp">
                                    <div class="drop-file-info-icon-aghp">
                                        <div class="drop-file-info-icon-in-aghp">
                                            <i class="fa-regular fa-file-arrow-up"></i>
                                        </div>
                                    </div>
                                    <div class="drop-file-info-text-aghp">
                                        <p>Veuillez déposer le fichier suivant dans votre espace “Mes Fichiers”</p>
                                        <h3>{{ notif.type_attachment }}</h3>
                                    </div>
                                </div>
                                <div class="drop-file-info-right-aghp">
                                    <div class="drop-file-info-btn-aghp">
                                        <a class="btn default-btnH40-aehp default-Bborder-btn-aehp" href="#"
                                            v-on:click="this.$router.push('/assure/dashboard/fichiers')">Aller déposer
                                            mon fichier</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="claim-timeline-block-main-aehp">
                            <div class="claim-timeline-block-in-aehp">
                                <div class="claim-timeline-title-aehp">
                                    <h3>Statut de votre sinistre</h3>
                                </div>
                                <div class="Ctimeline-block-aehp">
                                    <ul>
                                        <li id='step-1' class="current">
                                            <div class="Ctimeline-circle-aehp"></div>
                                            <div class="Ctimeline-text-aehp">Déclaration de sinistre enregistrée</div>
                                        </li>
                                        <li id='step-2'>
                                            <div class="Ctimeline-circle-aehp"></div>
                                            <div class="Ctimeline-text-aehp">Demande prise en charge</div>
                                        </li>
                                        <li id='step-3'>
                                            <div class="Ctimeline-circle-aehp"></div>
                                            <div class="Ctimeline-text-aehp">Instruction<br> en cours</div>
                                        </li>
                                        <li id='step-4'>
                                            <div class="Ctimeline-circle-aehp"></div>
                                            <div class="Ctimeline-text-aehp">Règlement<br> en cours</div>
                                        </li>
                                        <li id='step-5'>
                                            <div class="Ctimeline-circle-aehp"></div>
                                            <div class="Ctimeline-text-aehp">Dossier clos</div>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <div class="claim-box-main-aehp">
                  <div class="accordion" id="accordionExample">
                    <div class="card claim-box1-aehp">
                      <div class="card-header claim-box1-head-aehp" id="claim_heading">
                        <a href="#" class="" data-toggle="collapse" data-target="#claim_collapse1" aria-expanded="true" aria-controls="claim_collapse1">
                          Détail de toutes les étapes
                        </a>
                      </div>
                      <div id="claim_collapse1" class="collapse show" aria-labelledby="claim_heading" data-parent="#accordionExample">
                        <div class="card-body claim-box1-body-aehp">
                          <div class="claim-list-main-aehp">
                            <table width="100%" class="table table-borderless table-striped">
                              <thead>
                                <tr>
                                  <th style="width:150px;" scope="col">Dates</th>
                                  <th style="width:calc(100% - 150px);" scope="col">Étapes</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style="width:150px; font-weight:600;" scope="row">
                                    <p>30/04/2021</p>
                                  </td>
                                  <td style="width:calc(100% - 150px);">
                                    <p>Lorem ipsum dolor es</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="width:150px; font-weight:600;" scope="row">
                                    <p>30/04/2021</p>
                                  </td>
                                  <td style="width:calc(100% - 150px);">
                                    <p>Lorem ipsum dolor es</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="width:150px; font-weight:600;" scope="row">
                                    <p>30/04/2021</p>
                                  </td>
                                  <td style="width:calc(100% - 150px);">
                                    <p>Lorem ipsum dolor es</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="width:150px; font-weight:600;" scope="row">
                                    <p>30/04/2021</p>
                                  </td>
                                  <td style="width:calc(100% - 150px);">
                                    <p>Lorem ipsum dolor es</p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                            </div>
                        </div>
                        <div class="declarant-informations-main-aehp">
                            <div class="decl-informations-box1-aehp">
                                <div class="Dinformation-box-title-aehp">
                                    <h2>Renseignements relatifs à l'assuré</h2>
                                </div>
                                <div class="Dinformation-box1-in-aehp">
                                    <div class="Dinformation-row1-aehp">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Déclarant</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ declarant.name }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Dinformation-row1-aehp">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Adresse de correspondance</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ declarant.street }} - {{ declarant.zipcode }} {{
                                                        declarant.city }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Dinformation-row1-aehp">
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Téléphone</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ declarant.phone_number }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Email</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ declarant.email }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="decl-informations-box1-aehp">
                                <div class="Dinformation-box-title-aehp">
                                    <h2>Renseignements relatifs à la construction</h2>
                                </div>
                                <div class="Dinformation-box1-in-aehp">
                                    <div class="Dinformation-row1-aehp">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Souscripteur</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ construction.manufacturer_name }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Dinformation-row1-aehp">
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>N° de contrat</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ sinister.contract_number_do }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Nom du maître d’ouvrage</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ construction.first_owner_name }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Dinformation-row1-aehp">
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Date DROC</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ construction.settlement_date_opening_site }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Date PV de réception</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ construction.receipt_work_date }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Dinformation-row1-aehp">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Adresse de la construction sinistrée</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ construction.street }} - {{ construction.zipcode }} {{
                                                        construction.city }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="decl-informations-box1-aehp">
                                <div class="Dinformation-box-title-aehp">
                                    <h2>Renseignements relatifs au sinistre</h2>
                                </div>
                                <div class="Dinformation-box1-in-aehp">
                                    <div class="Dinformation-row1-aehp">
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Date du sinistre</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ claim.date_disorder }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Date de la déclaration</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ sinister.date_created }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Dinformation-row1-aehp">
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Date de réception de la déclaration</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ sinister.open_hour }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Garantie visée</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ sinister.garantees }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="disorders.length > 0" class="Dinformation-row1-aehp">`
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <h3>Description des désordres</h3>
                                            <div v-for="(items, key) in disorders" :key="key"
                                                class="declarant-info-field1-in-aehp">
                                                <!-- DESCRIPTION_1 -->
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ items.id }}</p>
                                                </div>
                                                <div class="desc-disorders-block-main-aehp">
                                                    <div v-if="items.description" class="Ddisorders-row1-aehp">
                                                        <div
                                                            class="col-lg-12 col-md-12 col-sm-12 col-12 Ddisorders-info-field1-aehp">
                                                            <div class="Ddisorders-info-field1-in-aehp">
                                                                <h4>Description</h4>
                                                                <p>{{ items.description }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="Ddisorders-row1-aehp">
                                                        <div
                                                            class="col-lg-12 col-md-12 col-sm-12 col-12 Ddisorders-info-field1-aehp">
                                                            <div class="Ddisorders-info-field1-in-aehp">
                                                                <h4>Localisation</h4>
                                                                <p>{{ items.location }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="Ddisorders-row1-aehp">
                                                        <div
                                                            class="col-lg-12 col-md-12 col-sm-12 col-12 Ddisorders-info-field1-aehp">
                                                            <div class="Ddisorders-info-field1-in-aehp">
                                                                <h4>Importance</h4>
                                                                <p>{{ items.importance }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="items.material_fall_risk || items.material_fall_risk === false"
                                                        class="Ddisorders-row1-aehp">
                                                        <div
                                                            class="col-lg-6 col-md-6 col-sm-12 col-12 Ddisorders-info-field1-aehp">
                                                            <div class="Ddisorders-info-field1-in-aehp">
                                                                <h4>Infiltrations</h4>
                                                                <p>{{ items.engenders_infiltration ? 'Oui' : 'Non' }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="col-lg-6 col-md-6 col-sm-12 col-12 Ddisorders-info-field1-aehp">
                                                            <div class="Ddisorders-info-field1-in-aehp">
                                                                <h4>Risque de chute de matériaux</h4>
                                                                <p>{{ items.material_fall_risk ? 'Oui' : 'Non' }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else class="Ddisorders-row1-aehp">
                                                        <div
                                                            class="col-lg-6 col-md-6 col-sm-12 col-12 Ddisorders-info-field1-aehp">
                                                            <div class="Ddisorders-info-field1-in-aehp">
                                                                <h4>Origine</h4>
                                                                <p>{{ items.origin ? 'Apparente' : 'Non accessible' }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="findExpert[0]" class="Dinformation-row1-aehp">
                                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 declarant-info-field1-aehp">
                                            <div class="declarant-info-field1-in-aehp">
                                                <h3>Expert saisi</h3>
                                                <div class="Dinfo-field1-text-aehp">
                                                    <p>{{ findExpert[0].id_expert.name }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-12 declarant-info-field1-aehp">
                      <div class="declarant-info-field1-in-aehp">
                        <h3>Référence Expert</h3>
                        <div class="Dinfo-field1-text-aehp">
                          <p>KJH98790</p>
                        </div>
                      </div>
                    </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </section>
        <!-- CONTAIN_END -->

        <!-- ADD_USER_START -->
        <div class="message-overlay-aehp"></div>
        <div id="message_sidebar" class="message-sidebar-main-aehp">
            <div class="message-sidebar-title-aehp">
                <h2>Messagerie</h2>
                <div class="message-close-aehp" v-on:click="closeMessage()">
                    <a href="javascript:void(0)"><i class="fa-solid fa-xmark"></i></a>
                </div>
            </div>
            <div class="message-sidebar-bot-aehp">
                <div class="messaging-block-main-aehp">
                    <div id="scrollBottom" class="message-chat-main-aehp optiscroll">
                        <Messages v-if="refreshMessage" :id="sinister.id" :key="messageSend" profil="insured" />
                    </div>
                    <div class="message-chat-Tbox-main-aehp">
                        <div class="message-chat-Tbox-in-aehp">
                            <div class="message-chat-Tbox-box-aehp">
                                <textarea v-model="messageContent" cols="1"
                                    placeholder="Ecrire un message à votre conseiller" rows="1"></textarea>
                                <input type="submit" v-on:click="sendMessage" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- PROCESS_SAV_START -->
        <div id="Process_sav_rcp" class="rcd-information-rcp">
            <div class="rcd-close-rcp">
                <a href="javascript:void(0)"><i class="fal fa-times"></i></a>
            </div>
            <div class="rcd-info-box-rcp optiscroll">
                <div class="rcd-info-box-in-rcp">
                    <div class="rcd-info-box-pad-rcp">
                        <div class="rcd-title-rcp">
                            <h2>Demander un justificatif</h2>
                        </div>
                        <div class="rdc-speaker-rcp">
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">Adresse e-mail <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input placeholder="Texte" type="text">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">OBJET <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input placeholder="Texte" type="text">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="default-editor-block-dp default-editor-padding-dp">put editor here</div>
                            <div class="default-files-list-dp">
                                <div class="file-progress-app file-progress-green-app">
                                    <div class="file-progress-left-app">
                                        <i class="far fa-file-pdf"></i>
                                    </div>
                                    <div class="file-progress-right-app">
                                        <div class="file-progress-top-app">
                                            <div class="file-progress-top-left-app">
                                                <div class="file-progress-file-name-app">Nom du fichier pdf</div>
                                                <div class="file-progress-process-app">1,1 Mo</div>
                                            </div>
                                            <div class="file-progress-top-right-app">
                                                <div class="file-progress-delete-app"><a href="#"><i
                                                            class="far fa-trash-alt"></i></a></div>
                                                <div class="file-progress-correct-app"><a href="#"><i
                                                            class="far fa-check"></i></a></div>
                                            </div>
                                        </div>
                                        <div class="file-progress-bar-app">
                                            <div class="file-progress-bar-main-app">
                                                <div class="file-progress-bar-in-app" style="width:100%;"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="file-progress-app">
                                    <div class="file-progress-left-app">
                                        <i class="far fa-file-pdf"></i>
                                    </div>
                                    <div class="file-progress-right-app">
                                        <div class="file-progress-top-app">
                                            <div class="file-progress-top-left-app">
                                                <div class="file-progress-file-name-app">Nom du fichier pdf</div>
                                                <div class="file-progress-process-app">Upload...</div>
                                            </div>
                                            <div class="file-progress-top-right-app">
                                                <div class="file-progress-cancel-app"><a href="#"><i
                                                            class="far fa-times-circle"></i></a></div>
                                            </div>
                                        </div>
                                        <div class="file-progress-bar-app">
                                            <div class="file-progress-bar-main-app">
                                                <div class="file-progress-bar-in-app" style="width:70%;"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rcd-info-bot-bar-rcp">
                <div class="rcd-info-bot-left-rcp">
                    <div class="cancel-btn-main-hp"><a class="btn default-btnH40-aehp default-Gray-border-btn-aehp"
                            href="#">Annuler</a>
                    </div>
                </div>
                <div class="rcd-info-bot-right-rcp">
                    <div class="join-btn-main-hp"><a class="btn default-btnH40-aehp default-Gray-border-btn-aehp"
                            href="#"><i class="far fa-paperclip-vertical"></i>Joindre</a></div>
                    <div class="confirm-btn-main-hp"><a class="btn default-btnH40-aehp" href="#"><i
                                class="far fa-paper-plane"></i>Envoyer la demande</a></div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <!-- PROCESS_SAV_END -->
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import $ from 'jquery';
import Messages from '@/components/administrator/Messages.vue';
// import vuecookie from 'vue-cookie'
export default {
  name: 'Dashboard',
  data() {
    return {
      refreshMessage: false,
      messageSend: 0,
      messageContent: null,
    };
  },
  components: {
    Messages
  },
  computed: {
    ...mapActions('dashboardSinister', ['dataSinister']),
    ...mapState('dashboardSinister', ['sinister', 'construction', 'declarant', 'claim', 'disorders', 'attachments', 'iban', 'nbMessages', 'findExpert']),
  },
  beforeMount() {
    this.$store.dispatch('dashboardSinister/dataSinister')
        .then((res) => {
          console.log('----', res);
          console.log(res);
          for (let index = 1; index < res; index++) {
            console.log(index)
            document.getElementById(`step-${index}`).classList.add('active');
          }
          document.getElementById(`step-${res}`).classList.add('current');
        })
        .catch(err => console.log(err))
  },
  methods: {
    logOut() {
      localStorage.removeItem('jwt');
      this.$router.push('/assure')
    },
    openMessage() {
      $(".message-overlay-aehp").fadeToggle();
      $("#message_sidebar").toggleClass("open");
      this.messageSend = this.messageSend + 1;
      this.refreshMessage = true;
      this.$store.commit('dashboardSinister/UPDATE_MESSAGES', 0)
    },
    closeMessage() {
      $(".message-overlay-aehp").fadeOut();
      $("#message_sidebar").removeClass("open");
    },
    sendMessage() {
      const token = localStorage.getItem('jwt');
      console.log('Components : ', token);
      this.$store.dispatch('adminMessage/send', {
        idSinister: this.sinister.id,
        message: this.messageContent,
        token,
        from: 'insured'
      })
          .then(() => {
            this.messageSend = this.messageSend + 1;
            this.messageContent = null;
          })
    }
  }
}
</script>



<style scoped>
</style>
