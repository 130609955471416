<template>
    <div class="wrapper">
        <!-- CONTAIN_START -->
        <section id="contain">
            <div class="sidebar-main-aedecp">
                <div class="logo-main-aedecp"><a href="#"><img alt="" src="~@/assets/images/logo_main_white_hp.svg"></a>
                </div>
                <div class="sidebar-content-aedecp optiscroll">
                    <div class="sidebar-content-in-aedecp">
                        <div class="decl-steps-main-aedecp">
                            <ul>
                                <!--                <li :class="stepOneData.state">-->
                                <!--                  <a href="#" v-on:click="changeStep({action : 'StepOne'})">Bienvenue</a>-->
                                <!--                </li>-->
                                <li :class="{ 'current': stepNumber === 2, 'active': stepNumber > 2 }">
                                    <a href="#" v-on:click="changeStep({ action: 'StepTwo' })">Déclarant</a>
                                </li>
                                <li :class="{ 'current': stepNumber === 3, 'active': stepNumber > 3 }">
                                    <a href="#" v-on:click="changeStep({ action: 'StepThree' })">Renseignements relatifs
                                        à la
                                        construction</a>
                                </li>
                                <li :class="{ 'current': stepNumber === 4, 'active': stepNumber > 4 }">
                                    <a href="#" v-on:click="changeStep({ action: 'End' })">Renseignements relatifs au
                                        sinistre</a>
                                </li>
                                <!--                <li :class="stepFiveData.state">-->
                                <!--                  <a href="#" v-on:click="changeStep({action : 'StepSixAdmin'})">Signature</a>-->
                                <!--                </li>-->

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="declaration-close-aedecp"><a v-on:click="this.$router.push('/gestionnaire')"><i
                            class="fa-regular fa-xmark"></i>Quitter et abandonner la déclaration</a></div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 content-main-aedecp">
                <div class="top-bar-main-aedecp">
                    <div class="tbar-left-aedecp">
                        <div class="logo-main-mobile-aedecp"><a href=# v-on:click="backStep">
                                <img alt="" src="~@/assets/images/logo_main_hp.svg"></a>
                        </div>
                        <div v-if="stepNumber < 6" class="tbar-back-main-aedecp"><a href=# v-on:click="backStep"><i
                                    class="fa-regular fa-arrow-left"></i></a></div>
                        <div class="tbar-user-info-main-aedecp">
                            <div class="tbar-user-text-aedecp">
                                <h2>{{ this.$store.state.contract.produit }}</h2>
                                <p>Contrat n° <span>{{ this.$store.state.contract.contract_number }}</span></p>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="tbar-right-aedecp">
                        <div class="declaration-numbers-aedecp"><span>{{ stepNumber }}/</span>5</div>
                        <div class="mobile-menu-icon-aedecp">
                            <div class="menu-toggle-btn-aedecp">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <StepTwo v-if="stepNumber === 2" v-on:click="changeStep" />
                <StepThree v-if="stepNumber === 3" v-on:click="changeStep" />
                <StepFour v-if="stepNumber === 4" :new='newSinister' v-on:click="changeStep" />
                <StepSixAdmin v-if="stepNumber === 6" :res="resData" v-on:click="changeStep" />
            </div>
            <div class="clearfix"></div>
        </section>
        <!-- CONTAIN_END -->

    </div>
</template>

<script>
import StepTwo from '@/components/declaration/StepTwo.vue';
import StepThree from '@/components/declaration/StepThree.vue';
import StepFour from '@/components/declaration/StepFour.vue';
import StepSixAdmin from '@/components/declaration/finalStepAdmin.vue';
import { mapActions } from 'vuex';

export default {
    name: 'Declaration',
    props: ['newSinister'],
    components: {
        StepTwo,
        StepThree,
        StepFour,
        StepSixAdmin
    },
    data() {
        return {
            stepNumber: 2,
            resData: {},
        }
    },
    mounted() {
        const contractNumber = localStorage.getItem('contract_number');
        this.findContract(contractNumber);
    },
    methods: {
        ...mapActions('createSinistre', ['createSinister']),
        ...mapActions('contract', ['findContract']), 
        changeStep(step) {
            switch (step.action) {
                case ('StepTwo'):
                    this.stepNumber = 2;
                    break;
                case ('StepThree'):
                    this.stepNumber = 3;
                    break;
                case ('StepFour'):
                    this.stepNumber = 4;
                    break;
                case ('StepFive'):
                    this.stepNumber = 5;
                    break;
                case ('End'):
          this.showLoader = true;
          this.createSinister()
              .then((res) => {
                  this.resData = res;
                  this.stepNumber = 6;
              }
              ).catch((error) => {
                  this.changeStep({ action: 'StepFour' });
              }).finally(() => {
                  this.showLoader = false;
              });
          break;
      }
    },
    backStep() {
        if (this.stepNumber === 6) {
            this.stepNumber = 4;
        } else if (this.stepNumber === 4) {
            this.stepNumber = 3;
        } else if (this.stepNumber === 3) {
            this.stepNumber = 2;
        } else if (this.stepNumber === 2) {
        this.$router.push('/gestionnaire')
      }
    }
  },
    beforeRouteLeave(to, from, next) {
        localStorage.removeItem('contract');
        localStorage.removeItem('contract_number');
        next();
    },
}
</script>