<template>
    <div class="decl-welcome-block-aedecp">
        <div class="decl-welcome-block-in-aedecp">
            <div class="decl-welcome-box-aedecp decl-finish-box-aedecp">
                <div class="decl-welcome-icon-aedecp"><img src="~@/assets/images/finish_icon_aedecp.svg" alt=""></div>
                <h2>Le sinistre <span>{{res.ref}}</span> <br>est enregistré.</h2>
                <p>Ci-dessous les identifiants à fournir à l’assuré.</p>
                <div class="disaster-info-block-decmp">
                    <div class="disaster-info1-decmp">
                        <div class="disaster-info1-title-decmp">RÉFÉRENCE SINISTRE</div>
                        <div class="disaster-info1-text-decmp">{{res.number}}</div>
                    </div>
                    <div class="disaster-info1-decmp disaster-info2-decmp">
                        <div class="disaster-info1-title-decmp">MOT DE PASSE</div>
                        <div class="disaster-info1-text-decmp">{{res.password}}</div>
                    </div>
                </div>
                <div class="decl-welcome-btn-aedecp">
                    <a href="#" v-on:click="redirect" class="btn default-btn-aehp">Revenir au dashboard</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'StepSixAdmin',
  props: ['res'],
  methods: {
	redirect(){
		this.$router.push('/gestionnaire')
	}
  },
  mounted(){
      console.log(this.res);
  }

}
</script>
