<template>
    <div id="pdfGenerate" class="content-in-dbp dossier-page-dp">
        <div class="dossier-top-dp">
            <div class="dossier-return-dp"><a href="#" v-on:click="this.$router.push('/gestionnaire/sinistres')"><i
                        class="fal fa-long-arrow-left"></i> Retour</a></div>
            <div class="folder-main-dp">
                <div class="folder-left-dp">
                    <div class="folder-icon-dp"><i class="far fa-folder-open"></i></div>
                    <div class="folder-details-dp">
                        <div class="folder-company-dp">QBE</div>
                        <div class="folder-name-dp">
                            <span>Sinistre {{ claim.sinister_reference }}</span>
                        </div>
                        <div class="folder-date-dp">Dernière modification {{ claimUpdatedAt }}</div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="folder-right-dp">
                    <div class="inst-progress-box-dp">
                        <div class="dos-info-field-input-dp select2-custom-hp">
                            <select id="select-category" class="custom-select-2">
                                <optgroup v-for="(group,key) in  categories" :key="key" :label="translator(group.name)">
                                    <option v-for="(category,key) in group.children" :key="key"
                                        :value="'step-'+category.id">
                                        <template v-if="category.name === 'recorded'">Orientation à définir</template>
                                        <template v-else>{{ translator(category.name) }}</template>
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="dossier-bottom-dp">
            <div class="folder-nav-dp">
                <ul>
                    <li><a class="active" href="#rrac">Renseignements relatifs au chantier</a></li>
                    <li><a href="#rras">Renseignements relatifs au sinistre</a></li>
                    <li><a href="#rra">Renseignements relatifs à l’assuré</a></li>
                    <!-- <li><a href="#">Instruction</a></li> -->
                </ul>
            </div>
            <div class="dossier-info-dp">
                <div class="dossier-left-dp">
                    <div id="optiscrollDetails" class="dossier-information-main-dp optiscroll">
                        <div id="sinisterPdf" class="dossier-information-in-dp">
                            <div id="titlePdf"
                                style='display:none; align-items: center; justify-content: space-between; margin-bottom: 50px'>
                                <img alt="" src="/img/logo_main_hp.5e81559e.svg">
                                <p id="titlePdf" style="font-size: 30px;text-align:center; color : #233F92;">Sinistre
                                    {{ claim.sinister_reference }}</p>
                            </div>
                            <div id="rrac" class="dossier-info-box1-main-dp">
                                <div class="dossier-info-box1-tit-dp">
                                    <h2>Renseignements relatifs au chantier</h2>
                                </div>
                                <div class="dossier-info-box1-in-dp">
                                    <div class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div
                                                class="col-lg-6 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp Dinfo-field-readonly-dp">
                                                <div class="dos-info-field-label-dp">Souscripteur</div>
                                                <div class="dos-info-field-input-dp">{{ site.manufacturer_name }}</div>
                                            </div>
                                            <div
                                                class="col-lg-6 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp Dinfo-field-readonly-dp">
                                                <div class="dos-info-field-label-dp">N° de contrat</div>
                                                <div class="dos-info-field-input-dp">{{ site.contract_number }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">Nom du maître d’ouvrage</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input v-model="site.declarant" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">DATE DROC</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input :value="siteSettlementDateOpeningSite" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">Date de réception</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input :value="siteReceiptWorkDate" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dos-info-field1-sub-title-dp">
                                        <h3>Adresse de la construction sinistrée</h3>
                                    </div>
                                    <div class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">Rue</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input v-model="site.street" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">code postal</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input v-model="site.zipcode" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">VILLE</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input v-model="site.city" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="rras" class="dossier-info-box1-main-dp">
                                <div class="dossier-info-box1-tit-dp">
                                    <h2>Renseignements relatifs au sinistre</h2>
                                </div>
                                <div class="dossier-info-box1-in-dp">
                                    <div class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div class="col-lg-6 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">DATE du sinistre</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input :value="claimDateSinister" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">date de déclaration</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input :value="claimDateDeclaration" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div class="col-lg-6 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">DATE de réception de la déclaration
                                                </div>
                                                <div class="dos-info-field-input-dp">
                                                    <input :value="claimOpenHour" :placeholder="claimOpenHour"
                                                        class="custom-datepicker5" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div
                                                class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp Dinfo-field-Cbox-main-dp">
                                                <div class="dos-info-field-label-dp">GARantie(S) visée(S)</div>
                                                <div class="dos-info-field-check-list-dp">
                                                    <div class="Dinfo-field-Cbox1-rcp">
                                                        <div class="custom-control custom-checkbox">
                                                            <input id="Covered_warranty_option1"
                                                                class="custom-control-input"
                                                                name="Covered-warranty-option1" type="checkbox"
                                                                value="DO" v-on:change="updateGarantie($event)">
                                                            <label class="custom-control-label"
                                                                for="Covered_warranty_option1">DO</label>
                                                        </div>
                                                    </div>
                                                    <div class="Dinfo-field-Cbox1-rcp">
                                                        <div class="custom-control custom-checkbox">
                                                            <input id="Covered_warranty_option2"
                                                                class="custom-control-input"
                                                                name="Covered-warranty-option1" type="checkbox"
                                                                value="RCD" v-on:change="updateGarantie($event)">
                                                            <label class="custom-control-label"
                                                                for="Covered_warranty_option2">RCD</label>
                                                        </div>
                                                    </div>
                                                    <div class="Dinfo-field-Cbox1-rcp">
                                                        <div class="custom-control custom-checkbox">
                                                            <input id="Covered_warranty_option3"
                                                                class="custom-control-input"
                                                                name="Covered-warranty-option1" type="checkbox"
                                                                value="RCP" v-on:change="updateGarantie($event)">
                                                            <label class="custom-control-label"
                                                                for="Covered_warranty_option3">RCP</label>
                                                        </div>
                                                    </div>
                                                    <div class="Dinfo-field-Cbox1-rcp">
                                                        <div class="custom-control custom-checkbox">
                                                            <input id="Covered_warranty_option4"
                                                                class="custom-control-input"
                                                                name="Covered-warranty-option1" type="checkbox"
                                                                value="TRC" v-on:change="updateGarantie($event)">
                                                            <label class="custom-control-label"
                                                                for="Covered_warranty_option4">TRC</label>
                                                        </div>
                                                    </div>
                                                    <div class="Dinfo-field-Cbox1-rcp">
                                                        <div class="custom-control custom-checkbox">
                                                            <input id="Covered_warranty_option5"
                                                                class="custom-control-input"
                                                                name="Covered-warranty-option1" type="checkbox"
                                                                value="CNR" v-on:change="updateGarantie($event)">
                                                            <label class="custom-control-label"
                                                                for="Covered_warranty_option5" value="CNR">CNR</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="html2pdf__page-break"></div>
                                    <div class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp dos-info-field-label-desc-dp">
                                                    Désordre(s)</div>
                                                <div class="DI-desc-disorders-block-main-dp">
                                                    <div v-for="(disorder, key) in claim.disorders" :key="key"
                                                        class="DI-desc-disorders-box1-main-dp">
                                                        <div class="DI-desc-disorders-box1-title-dp">
                                                            <h2>{{ disorder.name }}</h2>
                                                        </div>
                                                        <div class="DI-desc-Dorders-box-dp">
                                                            <div v-if="disorder.location" class="dos-info-field1-dp">
                                                                <div class="dos-info-field1-in-dp">
                                                                    <div
                                                                        class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                                        <div class="dos-info-field-label-dp">
                                                                            Localisation</div>
                                                                        <div class="dos-info-field-input-dp">
                                                                            <textarea
                                                                                v-model="claim.disorders[key].location"
                                                                                onkeyup="textAreaAdjust(this)"
                                                                                v-on:change="registerButton = true"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="disorder.importance" class="dos-info-field1-dp">
                                                                <div class="dos-info-field1-in-dp">
                                                                    <div
                                                                        class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                                        <div class="dos-info-field-label-dp">Importance
                                                                        </div>
                                                                        <div class="dos-info-field-input-dp">
                                                                            <textarea
                                                                                v-model="claim.disorders[key].importance"
                                                                                onkeyup="textAreaAdjust(this)"
                                                                                v-on:change="registerButton = true"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="disorder.description" class="dos-info-field1-dp">
                                                                <div class="dos-info-field1-in-dp">
                                                                    <div
                                                                        class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                                        <div class="dos-info-field-label-dp">DESCRIPTION
                                                                        </div>
                                                                        <div class="dos-info-field-input-dp">
                                                                            <textarea
                                                                                v-model="claim.disorders[key].description"
                                                                                onkeyup="textAreaAdjust(this)"
                                                                                placeholder="Vulputate odio vitae aenean sollicitudin congue at. "
                                                                                v-on:change="registerButton = true"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="disorder.engenders_infiltration || disorder.engenders_infiltration === false"
                                                                class="dos-info-field1-dp">
                                                                <div class="dos-info-field1-in-dp">
                                                                    <div
                                                                        class="col-lg-6 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                                        <div class="dos-info-field-label-dp">
                                                                            infiltrations</div>
                                                                        <div class="dos-info-field-input-dp">
                                                                            <select
                                                                                v-model="claim.disorders[key].engenders_infiltration"
                                                                                class="custom-select"
                                                                                v-on:change="registerButton = true">
                                                                                <option :value="true">Oui</option>
                                                                                <option :value="false">Non</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-lg-6 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                                        <div class="dos-info-field-label-dp">Risque de
                                                                            chute de matériaux</div>
                                                                        <div class="dos-info-field-input-dp">
                                                                            <select
                                                                                v-model="claim.disorders[key].material_fall_risk"
                                                                                class="custom-select"
                                                                                v-on:change="registerButton = true">
                                                                                <option :value="true">Oui</option>
                                                                                <option :value="false">Non</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="disorder.origin || disorder.origin === false"
                                                                class="dos-info-field1-dp">
                                                                <div class="dos-info-field1-in-dp">
                                                                    <div
                                                                        class="col-lg-6 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                                        <div class="dos-info-field-label-dp">Origine
                                                                        </div>
                                                                        <div class="dos-info-field-input-dp">
                                                                            <select
                                                                                v-model="claim.disorders[key].origin"
                                                                                class="custom-select"
                                                                                v-on:change="registerButton = true">
                                                                                <option :value="true">Apparente</option>
                                                                                <option :value="false">Non accessible
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="html2pdf__page-break"></div>
                            <div id="rra" class="dossier-info-box1-main-dp">
                                <div class="dossier-info-box1-tit-dp">
                                    <h2>Renseignements relatifs à l’assuré</h2>
                                </div>
                                <div class="dossier-info-box1-in-dp">
                                    <div class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">déclarant</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input v-model="declarant.name" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">ADRESSE Du déclarant</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input v-model="declarant.street" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">code postal</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input v-model="declarant.zipcode" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">VILLE</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input v-model="declarant.city" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">Téléphone</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input v-model="declarant.phone_number" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 col-12 dos-info-field1-col1-dp">
                                                <div class="dos-info-field-label-dp">Email</div>
                                                <div class="dos-info-field-input-dp">
                                                    <input v-model="declarant.email" type="text"
                                                        v-on:change="registerButton = true">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="dos-info-field1-dp">
                      <div class="dos-info-field1-in-dp">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                              <div class="dos-info-field-label-dp">Bénéficiaire 1</div>
                              <div class="dos-info-field-input-dp">
                                  <input type="text" placeholder="Damien Chweudura">
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="dos-info-field1-dp">
                      <div class="dos-info-field1-in-dp">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                              <div class="dos-info-field-label-dp">Bénéficiaire 2</div>
                              <div class="dos-info-field-input-dp">
                                  <input type="text" placeholder="Laura Chweudura">
                              </div>
                          </div>
                      </div>
                  </div> -->
                                    <div class="dos-info-field1-dp">
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="instruction " class="dossier-info-box1-main-dp">
                                <div class="dossier-info-box1-tit-dp">
                                    <h2>Instruction</h2>
                                </div>
                                <div class="dossier-info-box1-in-dp">
                                    <div v-if="instruction && instruction.createdAt" class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div
                                                class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp Dinfo-field-readonly-dp">
                                                <div class="dos-info-field-label-dp">PROCESS SAV</div>
                                                <div class="dos-info-field-input-dp">{{ instruction.createdAt }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="instruction && instruction.id_expert" class="dos-info-field1-dp">
                                        <div class="dos-info-field1-in-dp">
                                            <div
                                                class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp Dinfo-field-readonly-dp">
                                                <div class="dos-info-field-label-dp">Expert saisi</div>
                                                <div class="dos-info-field-input-dp">{{ instruction.id_expert.name }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dossier-right-dp">
                    <div class="dossier-right-in-dp">
                        <div class="message-btn-main-dp">
                            <div class="message-btn-in-dp">
                                <a id="openMessage" class="btn default-btn-aehp default-Dblue-btn-aehp"
                                    href="javascript:void(0);" v-on:click="openMessage"><i
                                        class="fa-regular fa-envelope-open"></i>
                                    Messagerie
                                    <div v-if="nbMessages > 0" class="Msg-btn-notification-aehp">{{ nbMessages }}</div>
                                </a>
                            </div>
                        </div>
                        <div id="filesScroll" style="position:relative; height: 400px; margin-top: 40px">
                            <div class="action-box-dp">
                                <div class="action-box-in-dp">
                                    <div class="action-title-dp">Actions sur le dossier</div>
                                    <div class="action-btns-dp">
                                        <div class="action-btn-dp">
                                            <a id="openRcd"
                                                class="btn default-btnH40-aehp default-green-btn-aehp disabled "
                                                href="javascript:void(0)" v-on:click="update"><i
                                                    class="far fa-edit"></i>
                                                Enregistrer</a>
                                        </div>
                                        <div class="action-btn-dp">
                                            <a id="openRproofd" class="btn default-btnH40-aehp"
                                                href="javascript:void(0)" v-on:click="openJustif"><i
                                                    class="far fa-file-import"></i> Demander un justificatif</a>
                                        </div>
                                        <div class="action-btn-dp" v-on:click="openSav">
                                            <a id="openProcessSAV" class="btn default-btnH40-aehp"
                                                href="javascript:void(0)"><i class="far fa-briefcase"></i> Process
                                                SAV</a>
                                        </div>
                                        <div class="action-btn-dp">
                                            <a id="openMissionOrder" class="btn default-btnH40-aehp"
                                                href="javascript:void(0)" v-on:click="openMissionOrder"><i
                                                    class="far fa-list-check"></i> Ordre de mission</a>
                                        </div>
                                        <div class="action-btn-dp">
                                            <a id="openNotifications" class="btn default-btnH40-aehp"
                                                href="javascript:void(0)" v-on:click="openNotifications()"><i
                                                    class="far fa-envelopes-bulk"></i> Notifications</a>
                                        </div>
                                        <div class="action-btn-dp">
                                            <a id="openDocument"
                                                class="btn default-btnH40-aehp default-Gray-border-btn-aehp"
                                                href="javascript:void(0)" @click="downloadPdfSinister"><i
                                                    class="far fa-file-pdf"></i>
                                                Exporter en PDF</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="file-box-dp">
                                <div class="file-box-in-dp">
                                    <div class="action-title-dp">Fichiers attachés ({{ filesLength }})</div>
                                    <div class="file-box-list-dp optiscroll">
                                        <div class="file-box-list-in-dp">
                                            <div v-for="(file, key) in files" :key="key" class="file-box-item-dp">
                                                <a href="#" v-on:click="download(file)">
                                                    <div class="file-icon-dp">
                                                        <div class="file-icon-in-dp">
                                                            <i class="far fa-file-pdf"></i>
                                                        </div>
                                                    </div>
                                                    <div class="file-name-dp">
                                                        <div class="file-name-in-dp">{{ file.name }}</div>
                                                        <div class="file-name-date-dp">{{ file.createdAt }}</div>
                                                    </div>
                                                    <div class="file-arrow-dp">
                                                        <i class="fal fa-long-arrow-right"></i>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MESSAGING_SIDEBAR_START -->
        <div class="message-overlay-aehp"></div>
        <div id="message_sidebar" class="message-sidebar-main-aehp">
            <div class="message-sidebar-title-aehp">
                <h2>Messagerie</h2>
                <div class="message-close-aehp" v-on:click="closeMessage">
                    <a href="javascript:void(0)"><i class="fa-solid fa-xmark"></i></a>
                </div>
            </div>
            <div class="message-sidebar-bot-aehp">
                <div class="messaging-block-main-aehp">
                    <div id="scrollBottom" class="message-chat-main-aehp optiscroll">
                        <Messages v-if="refreshMessage" :id="idSinister" :key="messageSend" profil="administrator" />
                    </div>
                    <div class="message-chat-Tbox-main-aehp">
                        <div class="message-chat-Tbox-in-aehp">
                            <div class="message-chat-Tbox-box-aehp">
                                <textarea v-model="messageContent" cols="1" placeholder="Ecrire un message au déclarant"
                                    rows="1"></textarea>
                                <input type="submit" v-on:click="sendMessage" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- MESSAGING_SIDEBAR_END -->

        <!-- REQUEST_PROOF_INFORMATION_START -->
        <div class="rcd-overlay-rcp" v-on:click="close"></div>
        <div id="request_proof_dp" class="rcd-information-rcp">
            <div class="rcd-close-rcp">
                <a href="javascript:void(0)" v-on:click="close"><i class="fal fa-times"></i></a>
            </div>
            <div class="rcd-info-box-rcp optiscroll">
                <div class="rcd-info-box-in-rcp">
                    <div class="rcd-info-box-pad-rcp">
                        <div class="rcd-title-rcp">
                            <h2>Demander un justificatif</h2>
                        </div>
                        <div class="rdc-speaker-rcp">
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">Type de pièce justificative</div>
                                        <div class="dos-info-field-input-dp select2-custom-hp select2-label-custom-hp">
                                            <select v-model="askFile" class="custom-select-2">
                                                <option label="Copie de la mise en demeure adressée au constructeur"
                                                    value="Copie de la mise en demeure adressée au constructeur">Copie
                                                    de la mise en demeure
                                                    adressée au constructeur
                                                </option>
                                                <option label="Photographies du sinistre"
                                                    value="Photographies du sinistre">Photographies du
                                                    sinistre
                                                </option>
                                                <option label="Copie du Procès verbal de réception"
                                                    value="Copie du Procès verbal de réception">
                                                    Copie du Procès verbal de réception
                                                </option>
                                                <option label="Justificatif de votre qualité de propriétaire"
                                                    value="Justificatif de votre qualité de propriétaire">Justificatif
                                                    de votre qualité de
                                                    propriétaire
                                                </option>
                                                <option label="Justificatif de votre mandat de gestion"
                                                    value="Justificatif de votre mandat de gestion">Justificatif de
                                                    votre mandat de gestion
                                                </option>
                                                <option label="IBAN" value="bank">IBAN</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rcd-info-bot-bar-rcp">
                <div class="rcd-info-bot-left-rcp">
                    <div class="cancel-btn-main-hp" v-on:click="close"><a
                            class="btn default-btnH40-aehp default-Gray-border-btn-aehp" href="#">Annuler</a>
                    </div>
                </div>
                <div class="rcd-info-bot-right-rcp">
                    <div class="confirm-btn-main-hp"><a class="btn default-btnH40-aehp default-green-btn-aehp"
                            data-target="#sendRequestModal" data-toggle="modal" href="javascript:void(0)"
                            v-on:click="askFileRequest"><i class="far fa-check-circle"></i>Envoyer la demande</a></div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <!-- REQUEST_PROOF_INFORMATION_END -->

        <!-- PROCESS_SAV_START -->
        <div id="Process_sav_rcp" class="rcd-information-rcp">
            <div class="rcd-close-rcp">
                <a href="javascript:void(0)" v-on:click="closeSav"><i class="fal fa-times"></i></a>
            </div>
            <div class="rcd-info-box-rcp optiscroll">
                <div id='screenshot' class="rcd-info-box-in-rcp">
                    <div class="rcd-info-box-pad-rcp">
                        <p id="error-input">Champs manquants</p>
                        <div class="rcd-title-rcp">
                            <h2>Process SAV</h2>
                        </div>
                        <div class="rdc-speaker-rcp">
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">Adresse e-mail <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input v-model="sav.email" placeholder="Texte" type="text">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                        <div class="dos-info-field-label-dp">OBJET <strong>*</strong></div>
                                        <div class="dos-info-field-input-dp">
                                            <input v-model="sav.object" placeholder="Texte" type="text">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="default-editor-block-dp default-editor-padding-dp">
                                <QuillEditor :key="sav.key" v-model:content="sav.editorContent" contentType="html"
                                    theme="snow" />
                            </div>
                            <DragAndDrop style="margin-top:25px" v-on:updateImages="updateImages" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="rcd-info-bot-bar-rcp">
                <div class="rcd-info-bot-left-rcp">
                    <div class="cancel-btn-main-hp"><a class="btn default-btnH40-aehp default-Gray-border-btn-aehp"
                            href="#" v-on:click="closeSav">Annuler</a></div>
                </div>
                <div class="rcd-info-bot-right-rcp">
                    <!-- <div class="join-btn-main-hp"><a class="btn default-btnH40-aehp default-Gray-border-btn-aehp"><i class="far fa-paperclip-vertical"></i>Joindre</a></div> -->
                    <div class="confirm-btn-main-hp"><a class="btn default-btnH40-aehp" v-on:click="sendSav"><i
                                class="far fa-paper-plane"></i>Envoyer la demande</a></div>
                </div>
                <div class="clearfix" v-on:click="close"></div>
            </div>
        </div>
        <!-- PROCESS_SAV_END -->

        <!-- MISSION_ORDER_START -->
        <div id="Mission_order_rcp" class="rcd-information-rcp">
            <div class="rcd-close-rcp">
                <a href="javascript:void(0)" v-on:click="close"><i class="fal fa-times"></i></a>
            </div>
            <div v-if="!resOrder" class="loader">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div v-else>
                <div class="rcd-info-box-rcp optiscroll">
                    <div class="rcd-info-box-in-rcp">
                        <div id="screenOrder" class="rcd-info-box-pad-rcp">
                            <div class="rcd-title-rcp">
                                <p id="error-input-order">Champs manquants</p>
                                <h2>Ordre de mission</h2>
                            </div>
                            <div class="rdc-speaker-rcp">
                                <div class="dos-info-field1-dp">
                                    <div class="dos-info-field1-in-dp">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                            <div class="dos-info-field-label-dp">N/REF</div>
                                            <div class="dos-info-field-input-dp">
                                                <input v-model="order.vref" placeholder="" type="text">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dos-info-field1-dp">
                                    <div class="dos-info-field1-in-dp">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                            <div class="dos-info-field-label-dp">Expert <strong>*</strong></div>
                                            <div class="dos-info-field-input-dp">
                                                <select v-model="order.expert" class="custom-select">
                                                    <option v-for="(expert, key) in order.experts"
                                                        :id="expert.id_expert.id" :key="key"
                                                        :value="expert.id_expert.id">{{ expert.id_expert.name }}
                                                    </option>
                                                    <option value="autres">Autres</option>
                                                    <!-- <option>Autres</option> -->
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="order.expert === 'autres'" class="dos-info-field1-dp">
                                    <div class="dos-info-field1-in-dp">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                            <div class="dos-info-field-label-dp">Autres <strong>*</strong></div>
                                            <div class="dos-info-field-input-dp">
                                                <select id="otherExpert" class="custom-select">
                                                    <option v-for="(expert, key) in allExperts" :id="expert.name"
                                                        :key="key" :value="expert.id">
                                                        {{ expert.name }}
                                                    </option>
                                                    <!-- <option>Autres</option> -->
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dos-info-field1-dp">
                                    <div class="dos-info-field1-in-dp">
                                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                            <div class="dos-info-field-label-dp">OBJET <strong>*</strong></div>
                                            <div class="dos-info-field-input-dp">
                                                <input v-model="order.object" placeholder="" type="text">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <QuillEditor :key="sav.key" v-model:content="order.editorContent" contentType="html"
                                    theme="snow" />
                                <p style="margin-top: 20px"><strong>Pièces jointes : </strong></p>
                                <div v-for="(file,key) of order.images" :key="key" class="file-progress-app">
                                    <div class="file-progress-left-app">
                                        <i class="far fa-file-pdf"></i>
                                    </div>
                                    <div class="file-progress-right-app ">
                                        <div class="file-progress-top-app">
                                            <div class="file-progress-top-left-app">
                                                <div class="file-progress-file-name-app">{{ file.name }}</div>
                                            </div>
                                            <div class="file-progress-top-right-app">
                                                <div class="file-progress-cancel-app" @click="deleteFile(key)"><a
                                                        href="#"><i class="far fa-times-circle"></i></a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <Editor v-model="order.tab" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rcd-info-bot-bar-rcp">
                    <div class="rcd-info-bot-left-rcp">
                        <div class="cancel-btn-main-hp"><a class="btn default-btnH40-aehp default-Gray-border-btn-aehp"
                                href="#" v-on:click="close">Annuler</a>
                        </div>
                    </div>
                    <div class="rcd-info-bot-right-rcp">
                        <input id="fileOrder" style="display:none" type="file" @change="previewFiles">
                        <div class="join-btn-main-hp" v-on:click="triggerFile"><a
                                class="btn default-btnH40-aehp default-Gray-border-btn-aehp"><i
                                    class="far fa-paperclip-vertical"></i>Joindre</a>
                        </div>
                        <div class="confirm-btn-main-hp" v-on:click="sendOrder"><a class="btn default-btnH40-aehp"
                                href="#"><i class="far fa-paper-plane"></i>Envoyer la demande</a></div>
                    </div>
                    <div class="clearfix" v-on:click="close"></div>
                </div>
            </div>
        </div>
        <!-- MISSION_ORDER_END -->

        <Notifications v-if="showNotif" :idSinister="idSinister" @close="showNotif = false"
            @successMessage="successNotif()" />

        <!-- DOCUMENTS_START -->
        <div id="documents_rcp" class="rcd-information-rcp">
            <div class="rcd-close-rcp">
                <a href="javascript:void(0)"><i class="fal fa-times"></i></a>
            </div>
            <div class="rcd-info-box-rcp optiscroll">
                <div class="rcd-info-box-in-rcp">
                    <div class="rcd-info-box-pad-rcp">
                        <div class="rcd-title-rcp">
                            <h2>Dépôt de document DCI</h2>
                        </div>
                        <div class="rdc-speaker-rcp">
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div
                                        class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp Dinfo-field-readonly-dp">
                                        <div class="dos-info-field-label-dp">Type de document déposé</div>
                                        <div class="dos-info-field-input-dp">Dépôt rapport complémentaire</div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div
                                        class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp Dinfo-field-readonly-dp">
                                        <div class="dos-info-field-label-dp">Date de l'événement</div>
                                        <div class="dos-info-field-input-dp">02/10/2020</div>
                                    </div>
                                </div>
                            </div>
                            <div class="dos-info-field1-dp">
                                <div class="dos-info-field1-in-dp">
                                    <div
                                        class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp Dinfo-field-readonly-dp">
                                        <div class="dos-info-field-label-dp">Action effectuée par</div>
                                        <div class="dos-info-field-input-dp">Mikail TURKOGLU 31481300000000</div>
                                    </div>
                                </div>
                            </div>
                            <div class="download-doc-dop">
                                <a class="btn default-btnH40-aehp" href="#"><i
                                        class="far fa-file-pdf"></i>Nomdufichier.pdf</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rcd-info-bot-bar-rcp">
                <div class="rcd-info-bot-right-rcp">
                    <div class="confirm-btn-main-hp"><a class="btn default-btnH40-aehp" href="#"><i
                                class="far fa-long-arrow-left"></i>Revenir au dossier</a></div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <!-- DOCUMENTS_END -->

        <!-- ARCHIVER_MODAL_START -->
        <div id="archiverModal" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-mp modal-dialog-centered">
                <div class="modal-content modal-content-mp">
                    <div class="modal-body modal-body-mp">
                        <div class="modal-title-mp">
                            <div class="modal-title-icon-mp">
                                <div class="modal-title-icon-in-mp">
                                    <i class="far fa-archive"></i>
                                </div>
                            </div>
                            <div class="modal-title-text-mp">Archiver ce dossier</div>
                        </div>
                        <div class="modal-details-mp">
                            <p>Vous êtes sur le point d'archiver ce dossier. Voulez-vous continuer ?</p>
                        </div>
                    </div>
                    <div class="modal-footer-mp">
                        <div class="cancel-btn-main-hp"><a aria-label="Close" class="common-btn-hp cancel-btn-hp"
                                data-dismiss="modal" href="javascript:void(0)">Annuler</a></div>
                        <div class="confirm-btn-main-hp"><a aria-label="Close" class="common-btn-hp confirm-btn-hp"
                                data-dismiss="modal" href="javascript:void(0)"><i
                                    class="far fa-check-circle"></i>Archiver</a>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ARCHIVER_MODAL_END -->

        <!-- CONFIRM_RECOURS_MODAL_START -->
        <div id="confirmRecoursModal" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade"
            tabindex="-1">
            <div class="modal-dialog modal-dialog-mp modal-dialog-centered">
                <div class="modal-content modal-content-mp confirm-modal-content-mp"> <!-- confirm-modal-content-mp -->
                    <div class="modal-body modal-body-mp">
                        <div class="modal-title-mp">
                            <div class="modal-title-icon-mp">
                                <div class="modal-title-icon-in-mp">
                                    <i class="far fa-check-circle"></i>
                                </div>
                            </div>
                            <div class="modal-title-text-mp">Confimer le recours</div>
                        </div>
                        <div class="modal-details-mp">
                            <p>Vous êtes sur le point de confirmer votre recours.</p>
                        </div>
                    </div>
                    <div class="modal-footer-mp">
                        <div class="cancel-btn-main-hp"><a aria-label="Close" class="common-btn-hp cancel-btn-hp"
                                data-dismiss="modal" href="javascript:void(0)">Modifier le recours</a></div>
                        <div class="confirm-btn-main-hp"><a aria-label="Close" class="common-btn-hp confirm-btn-hp"
                                data-dismiss="modal" href="javascript:void(0)"><i
                                    class="far fa-check-circle"></i>Confirmer</a>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- CONFIRM_RECOURS_MODAL_END -->

        <!-- CANCEL_APPEAL_MODAL_START -->
        <div id="cancelAppealModal" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade"
            tabindex="-1">
            <div class="modal-dialog modal-dialog-mp modal-dialog-centered">
                <div class="modal-content modal-content-mp cancel-modal-content-mp"> <!-- cancel-modal-content-mp -->
                    <div class="modal-body modal-body-mp">
                        <div class="modal-title-mp">
                            <div class="modal-title-icon-mp">
                                <div class="modal-title-icon-in-mp">
                                    <i class="far fa-times-circle"></i>
                                </div>
                            </div>
                            <div class="modal-title-text-mp">Annuler la demande de recours</div>
                        </div>
                        <div class="modal-details-mp">
                            <p>Vous êtes sur le point d'annuler la demande de recours. Ceci aura pour effet de supprimer
                                toutes les
                                données saisies associées à cette demande. Êtes vous certains de vouloir effectuer cette
                                action ?</p>
                        </div>
                    </div>
                    <div class="modal-footer-mp">
                        <div class="cancel-btn-main-hp"><a aria-label="Close" class="common-btn-hp cancel-btn-hp"
                                data-dismiss="modal" href="javascript:void(0)">Reprendre le recours</a></div>
                        <div class="confirm-btn-main-hp"><a aria-label="Close" class="common-btn-hp confirm-btn-hp"
                                data-dismiss="modal" href="javascript:void(0)"><i
                                    class="far fa-times-circle"></i>Annuler</a>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- CANCEL_APPEAL_MODAL_END -->

        <!-- CONFIRM_TRANSFER_MODAL_START -->
        <div id="confirmTransferModal" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade"
            tabindex="-1">
            <div class="modal-dialog modal-dialog-mp modal-dialog-centered">
                <div class="modal-content modal-content-mp confirm-modal-content-mp"> <!-- confirm-modal-content-mp -->
                    <div class="modal-body modal-body-mp">
                        <div class="modal-title-mp">
                            <div class="modal-title-icon-mp">
                                <div class="modal-title-icon-in-mp">
                                    <i class="far fa-check-circle"></i>
                                </div>
                            </div>
                            <div class="modal-title-text-mp">Confirmation de transfert</div>
                        </div>
                        <div class="transfer-details-trp">
                            <div class="transfer-list-trp">
                                <div class="transfer-list-left-trp">Dossier e-CRAC n° CRAC2020000313</div>
                            </div>
                            <div class="transfer-list-trp">
                                <div class="transfer-list-left-trp">Ancien Expert</div>
                                <div class="transfer-list-right-trp">CERUTTI</div>
                            </div>
                            <div class="transfer-list-trp">
                                <div class="transfer-list-left-trp">Nouvel Expert</div>
                                <div class="transfer-list-right-trp">DROUAULT EXPERTISES</div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer-mp">
                        <div class="cancel-btn-main-hp"><a aria-label="Close" class="common-btn-hp cancel-btn-hp"
                                data-dismiss="modal" href="javascript:void(0)">Modifier le transfert</a></div>
                        <div class="confirm-btn-main-hp"><a aria-label="Close" class="common-btn-hp confirm-btn-hp"
                                data-dismiss="modal" href="javascript:void(0)"><i
                                    class="far fa-check-circle"></i>Confirmer</a>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- CONFIRM_TRANSFER_MODAL_END -->
        <!-- UPDATED_RCD_START -->
        <div class="green-box-main-top-dp">
            <div class="green-box-main-dp" style="display:none">
                <div class="error-text-lp"><i class="far fa-check-circle"></i>{{ messageAlert }} <a
                        v-if="messageAlertLink" :href="messageAlertLink" class="linkAlert" target="_blank">,
                        Cliquez-ici pour le consulter</a></div>
                <div class="error-box-close-lp"><a href="javascript:void(0)"><i class="fal fa-times"></i></a></div>
            </div>
        </div>
        <!-- UPDATED_RCD_END -->
    </div>
</template>

<script>

import $ from 'jquery';
// eslint-disable-next-line no-unused-vars
import Vue from 'vue';
import vuecookie from 'vue-cookie'
import Messages from '@/components/administrator/Messages.vue';
import _ from 'lodash';
import {QuillEditor} from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import DragAndDrop from '@/components/DragAndDrop/DragAndDrop.vue';
import html2canvas from 'html2canvas';
import Editor from "@/components/administrator/Editor.vue";
import html2pdf from '../../../node_modules/html2pdf.js'
import { convertToTimeZone } from '@/utils/date';
const moment = require('moment');
import Optiscroll from 'optiscroll';
import Notifications from '@/components/administrator/Notifications.vue';

export default {
  name: 'SinisterDetails',
  props: ['idSinister'],
  components: {
    Messages,
    QuillEditor,
    DragAndDrop,
    Editor,
    Notifications
  },
  computed: {
    claimsForWatcher() {
      return _.cloneDeep(this.registerButton)
    },
    notifications() {
      return _.cloneDeep(this.showNotif)
      },
      claimUpdatedAt() {
          return this.claim.updatedAt ? convertToTimeZone(this.claim.updatedAt, 'Europe/Paris', 'DD/MM/YYYY') : null
      },
      siteSettlementDateOpeningSite() {
          return this.site.settlement_date_opening_site ? convertToTimeZone(this.site.settlement_date_opening_site, 'Europe/Paris', 'DD/MM/YYYY') : null
      },
      siteReceiptWorkDate() {
          return this.site.receipt_work_date ? convertToTimeZone(this.site.receipt_work_date, 'Europe/Paris', 'DD/MM/YYYY') : null
      },
      claimDateSinister() {
          return this.claim.date_sinister ? convertToTimeZone(this.claim.date_sinister, 'Europe/Paris', 'DD/MM/YYYY') : null
      },
      claimDateDeclaration() {
          return this.claim.date_declaration ? convertToTimeZone(this.claim.date_declaration, 'Europe/Paris', 'DD/MM/YYYY') : null
      },
      claimOpenHour() {
          return this.claim.open_hour ? convertToTimeZone(this.claim.open_hour, 'Europe/Paris', 'DD/MM/YYYY HH:mm') : null
      }
  },
  watch: {
    'claimsForWatcher': function () {
      if (this.registerButton) {
        $('#openRcd').removeClass('disabled')
      }
    },
    'notifications': function () {
      if (this.showNotif) {
        $(".rcd-overlay-rcp").fadeToggle();
      }
    }
  },
  data() {
    return {
      site: {},
      claim: {},
      declarant: {},
      askFile: null,
      messageAlert: null,
      messageAlertLink: null,
      files: null,
      filesLength: 0,
      category: null,
      messageContent: null,
      messageSend: 0,
      refreshMessage: false,
      nbMessages: null,
      garantees: [],
      sav: {
        email: '',
        object: '',
        editorContent: null,
        images: [],
        key: 0
      },
      order: {
          email: '',
          object: '',
        editorContent: null,
        images: [],
        experts: null,
        tab: null,
        key: 0,
        expert: null,
        vref: null
      },
      registerButton: false,
      resOrder: false,
      allExperts: null,
      pdfOptions: {
        margin: 15,
        image: {
          type: 'jpeg',
          quality: 1,
        },
        html2canvas: {scale: 3},
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'p',
        },
      },
      exportFilename: 'my-custom-file.pdf',
      categoryTab: [],
      parentTab: [],
      showNotif: false,
      categories: null,
      instruction: null,
    }
  },
  mounted() {
    var that = this;
    $('#select-category').select2({placeholder: 'Catégories'}).on('change', function () {
        if (this.value !== '') {
            clearInterval(addCat)
            that.category = this.value
            that.registerButton = true
        }
    }).bind();
    
    

    this.$store.dispatch('sinister/findOne', this.idSinister)
        .then((res) => {
          console.log(res);
          if (res.findLastInstruction[0]) {
            if (res.findLastInstruction[0].createdAt) {
              res.findLastInstruction[0].createdAt = moment().format('DD/MM/YYYY')
            }
          }
          this.instruction = res.findLastInstruction[0];
          this.site = res.site;
          this.claim = res.claim;
          this.declarant = res.declarant;
          this.files = res.files;
          this.filesLength = res.files.length;
          this.category = 'step-' + res.category;
          this.nbMessages = res.nbMessages;
          this.sav.editorContent = res.trameSAV;
          this.order.vref = res.claim.sinister_reference;
          this.sav.key = this.sav.key + 1;
          this.categoryTab = res.allCategory;
          this.categories = res.categories;
          // Scroll for left content
          var winHeight = $(window).height();
          var top_bar_main_dbp = $(".top-bar-main-dbp").outerHeight();
          var dossier_top_dp = $(".dossier-top-dp").height();
          var folder_nav_dp = $(".folder-nav-dp").outerHeight();
          var total = winHeight - (top_bar_main_dbp + dossier_top_dp + folder_nav_dp);
          $(".dossier-info-dp").css("height", total + "px");
          const element = document.getElementById('optiscrollDetails')
          new Optiscroll(element);

          //Scroll for files content
          var message_btn_dp = $(".message-btn-main-dp").height();
          $(".message-btn-main-dp").css("height", message_btn_dp + "px");

          // const actionBox = $('.action-box-dp').outerHeight();
          // const defaultFilesHeight = $('.file-box-in-dp').outerHeight() + (57*this.files.length);
          // $("#filesScroll").css("height",actionBox + defaultFilesHeight +"px");
          const elementFiles = document.getElementById('filesScroll')
          new Optiscroll(elementFiles);
          this.order.tab = `
            <p><strong>Computation des délais :</strong></p>
            <table>
                <tbody>
                    <tr>
                        <th style="border: solid 1px black; padding: 5px">Jour J</th>
                        <td style="border: solid 1px black; padding: 5px">${moment(res.claim.open_hour, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')}</td>
                    </tr>
                    <tr>
                        <th style="border: solid 1px black; padding: 5px">J+60</th>
                        <td style="border: solid 1px black; padding: 5px">${moment(res.claim.open_hour, 'DD/MM/YYYY HH:mm').add(59, 'days').format('DD/MM/YYYY')}</td>
                    </tr>
                    <tr>
                        <th style="border: solid 1px black; padding: 5px">J+90</th>
                        <td style="border: solid 1px black; padding: 5px">${moment(res.claim.open_hour, 'DD/MM/YYYY HH:mm').add(89, 'days').format('DD/MM/YYYY')}</td>
                    </tr>
                </tbody>
            </table>`;
          res.garantees.forEach(element => {
            const name = element.id_garantees.name;
            const checkbox = document.querySelector('input[value="' + name + '"]');
            checkbox.checked = true;
            this.garantees.push(name);
          });
        });
    const addCat = setInterval(function () {
      $('#select-category').val(that.category).trigger("change")
    }, 200);


    const dossierMain = document.querySelector('.dossier-information-main-dp');
    dossierMain.addEventListener("scroll", () => {
        const titles = document.querySelectorAll(".dossier-info-box1-tit-dp");
        const navLinks = document.querySelectorAll('.folder-nav-dp ul li a');

        // Find the title that is most visible in the viewport
        let mostVisibleTitle = null;
        let maxVisibleArea = 0;

        titles.forEach((title) => {
            const rect = title.getBoundingClientRect();

            // Calculate how much of the element is visible in the viewport
            const visibleTop = Math.max(0, rect.top);
            const visibleBottom = Math.min(window.innerHeight, rect.bottom);
            const visibleHeight = Math.max(0, visibleBottom - visibleTop);

            // If this title has more visible area than the current max, update
            if (visibleHeight > maxVisibleArea) {
                maxVisibleArea = visibleHeight;
                mostVisibleTitle = title;
            }
        });

        // If we found a visible title, update the active class
        if (mostVisibleTitle) {
            // Remove active class from all links
            navLinks.forEach(link => {
                link.classList.remove('active');
            });

            // Get the section id from the most visible title's parent element
            const sectionId = mostVisibleTitle.closest('.dossier-info-box1-main-dp').id;

            // Find the corresponding nav link and set it active
            const correspondingLink = document.querySelector(`.folder-nav-dp ul li a[href="#${sectionId}"]`);
            if (correspondingLink) {
                correspondingLink.classList.add('active');
            }
        }
    });
    

  },
  methods: {
    translator(data) {
      return this.$store.getters['translator/translate'](data)
    },
    update() {
      const token = vuecookie.get('jwt');
      this.$store.dispatch('sinister/update', {
        site: this.site,
        claim: this.claim,
        declarant: this.declarant,
        id: this.idSinister,
        category: this.category,
        token,
        garantees: this.garantees
      })
          .then(() => {
            this.messageAlert = "Modifications effectuées";
            this.successMessage();
          })
          .catch(err => console.log(err))
    },
    openJustif() {
      $(".rcd-overlay-rcp").fadeToggle();
      $("#request_proof_dp").toggleClass("open");
    },
    close() {
      $(".rcd-overlay-rcp").fadeOut();
      $("#request_proof_dp").removeClass("open");
      $("#Process_sav_rcp").removeClass("open");
      $("#Mission_order_rcp").removeClass("open");
      $("#Notifications_sidebar_rcp").removeClass("open");

      $("#documents_rcp").removeClass("open");
    },
    askFileRequest() {
      const token = vuecookie.get('jwt');
      this.$store.dispatch('sinister/askFile', {file: this.askFile, idSinister: this.idSinister, token})
          .then(() => {
            this.category = 'step-' + 2;
            $('#select-category').val(this.category).trigger("change")
            this.close();
            this.messageAlert = "Demande de justificatif effectuée";
            this.successMessage();
          })
          .catch(err => console.log(err));
    },
    successMessage() {
      $(".green-box-main-dp").fadeIn();
      const that = this;
      const time = this.messageAlertLink ? 10000 : 3000;
      setTimeout(function () {
        $(".green-box-main-dp").fadeOut();
        that.messageAlertLink = null;
      }, time);
    },
      download(file) {
          this.$store.dispatch('files/link', file)
          .then((res) => {
            this.messageAlertLink = res;
            this.messageAlert = `Pdf enregistré sur Sharepoint`;
            this.successMessage();
          })
          .catch((err) => {
            console.log(err);
          })
    },
    openMessage() {
      $(".message-overlay-aehp").fadeToggle();
      $("#message_sidebar").toggleClass("open");
      this.messageSend = this.messageSend + 1;
      this.refreshMessage = true;
      this.nbMessages = 0;
    },
    closeMessage() {
      $(".message-overlay-aehp").fadeOut();
      $("#message_sidebar").removeClass("open");
    },
    sendMessage() {
      const token = vuecookie.get('jwt');
      this.$store.dispatch('adminMessage/send', {idSinister: this.idSinister, message: this.messageContent, token})
          .then(() => {
            this.messageSend = this.messageSend + 1;
            this.messageContent = null;
          })
    },
    updateGarantie(e) {
      this.registerButton = true;
      const element = this.garantees.includes(e.target.value);
      // element ?  this.renseignements_sinister.garantees.push(e.target.value) :  this.renseignements_sinister.garantees.filter((c) => { return c !== e.target.value });
      if (!element) {
        this.garantees.push(e.target.value);
      } else {
        _.remove(this.garantees, function (n) {
          return n == e.target.value
        })
      }
    },
    openSav() {
      $(".rcd-overlay-rcp").fadeToggle();
      $("#Process_sav_rcp").toggleClass("open");
    },
    closeSav() {
      $(".rcd-overlay-rcp").fadeOut();
      $("#Process_sav_rcp").removeClass("open");
    },
    updateImages(props) {
      this.sav.images = props.images;
    },
    updateImagesOrder(props) {
      this.order.images = props.images;
    },
    sendSav() {
      if (this.sav.email && this.sav.object && this.sav.editorContent) {
        let dataImage;
        $('#error-input').fadeOut();
        html2canvas(document.querySelector("#screenshot"), {scrollY: 0, scrollX: 0}).then(canvas => {
          dataImage = canvas.toDataURL();
          const token = vuecookie.get('jwt');
          this.getPdfSinister()
              .then((res) => {
                let sinisterPdf = res;
                this.$store.dispatch('sav/create', {
                  ...this.sav,
                  id: this.idSinister,
                  dataImage,
                  reference: this.claim.sinister_reference,
                  sinisterPdf,
                  token
                })
                    .then(() => {
                      $(".rcd-overlay-rcp").fadeOut();
                      $("#Process_sav_rcp").removeClass("open");
                      this.messageAlert = "Process SAV envoyé";
                      this.successMessage();
                    })
              })
        });
      } else {
        $('#error-input').fadeIn().css('display', 'inline-block');
      }
    },
    openMissionOrder() {
      $(".rcd-overlay-rcp").fadeToggle();
      $("#Mission_order_rcp").toggleClass("open");
      this.$store.dispatch('order/findInformations', {id: this.idSinister, zipcode: this.declarant.zipcode})
          .then((res) => {
            console.log(res)
            this.order.editorContent = res.findOrder;
            this.order.key = this.order.key + 1;
            this.resOrder = true;
            this.order.experts = res.expert;
            this.allExperts = res.allExperts;
          })
    },
    sendOrder() {
      if (this.order.expert) {
        const idExpert = this.order.expert === 'autres' ? $('#otherExpert').val() : this.order.expert;
        let dataImage;
        this.resOrder = false;
        html2canvas(document.querySelector("#screenOrder"), {scrollY: 0, scrollX: 0}).then(canvas => {
          dataImage = canvas.toDataURL();
          const token = vuecookie.get('jwt');
          let sinisterPdf;
          this.getPdfSinister()
              .then((res) => {
                sinisterPdf = res;
                this.$store.dispatch('order/create', {
                  id: this.idSinister, ...this.order,
                  idExpert: idExpert,
                  screen: dataImage,
                  reference: this.claim.sinister_reference,
                  imageSinister: sinisterPdf,
                  token
                })
                    .then(() => {
                      $(".rcd-overlay-rcp").fadeOut();
                      $("#Mission_order_rcp").removeClass("open");
                      this.messageAlert = "Ordre de mission envoyé";
                      this.successMessage();
                    })
                    .catch(err => console.log(err));
              });
        });
      } else {
        $('#error-input-order').fadeIn().css('display', 'inline-block');
      }
    },
    triggerFile() {
      $('#fileOrder').click()
    },
    previewFiles() {
      const input = document.querySelector("#fileOrder");
      let file = input.files[0];
      const images = this.order.images;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        images.push({name: file.name, state: '0', buffer: reader.result, type: file.type.split('/')[1]});
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    },
    deleteFile(index) {
      this.order.images.splice(index, 1);
      document.querySelector("#fileOrder").value = "";
    },
    getPdfSinister() {
      return new Promise((resolve) => {
        var opt = {
          margin: 5,
          filename: 'myfile.pdf',
        };
        $('#titlePdf').show();
        let base64;
        var element = document.getElementById('sinisterPdf');
        html2pdf().from(element).set(opt).outputPdf().then(function (pdf) {
          base64 = new Buffer.from(pdf, 'binary').toString('base64');
          resolve(base64)
        });
        $('#titlePdf').fadeOut();
      });
    },
    async downloadPdfSinister() {
      const pdf = await this.$store.dispatch('sinister/generatePdf', {id: this.idSinister});
      const blob = new Blob([pdf], {type: 'application/pdf'});
      const pdfUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a')
      link.href = pdfUrl;
      link.download = "SamplePDF.pdf";
      link.click();
      return;

      /*$('#titlePdf').show().css('display', 'flex');
      var opt = {
        margin: 5,
        filename: 'sinistre' + this.claim.sinister_reference + '.pdf',
      };
      var element = document.getElementById('sinisterPdf');
      let base64;
      const that = this
      html2pdf().from(element).set(opt).outputPdf().then(function (pdf) {
        base64 = new Buffer.from(pdf, 'binary').toString('base64');
        that.$store.dispatch('sinister/generatePdf', {base64, id: that.idSinister})
            .then((res) => {
              that.messageAlertLink = res;
              that.messageAlert = `Pdf enregistré sur Sharepoint`;
              that.successMessage();
            })
      });
      $('#titlePdf').fadeOut();*/
    },
    openNotifications() {
      this.showNotif = true;
    },
    successNotif() {
      this.messageAlert = "Pdf enregistré sur Sharepoint";
      this.successMessage();
    }
  }
}
</script>
<style scoped>
#error-input {
  border: red solid 1px;
  color: red;
  display: none;
  width: auto;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  text-align: center
}

#error-input-order {
  border: red solid 1px;
  color: red;
  display: none;
  width: auto;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  text-align: center
}

.loader {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.linkAlert {
  color: white;
  text-decoration: underline;
}
</style>


