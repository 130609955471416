<template>
    <!-- NOTIFICATIONS_START -->
    <div class="rcd-information-rcp" id="Notifications_sidebar_rcp">
        <div class="rcd-close-rcp">
            <a href="javascript:void(0)" @click="closeAll()"><i class="fal fa-times"></i></a>
        </div>
        <div class="loader" style="text-align:center" v-if="loader">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div class="rcd-info-box-rcp optiscroll" v-else>
            <div class="rcd-info-box-in-rcp">
                <div class="rcd-info-box-pad-rcp">
                    <div class="rcd-title-rcp">
                        <h2>Notifications</h2>
                    </div>
                    <div class="rdc-speaker-rcp">
                        <div class="dos-info-field1-dp">
                            <div class="dos-info-field1-in-dp">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                    <div class="dos-info-field-label-dp">V/REF <strong>*</strong></div>
                                    <div class="dos-info-field-input-dp">
                                        <input v-model="vref" type="text">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dos-info-field1-dp">
                            <div class="dos-info-field1-in-dp">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                    <div class="dos-info-field-label-dp">num. RAR</div>
                                    <div class="dos-info-field-input-dp">
                                        <input v-model="numrar" type="text" placeholder="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dos-info-field1-dp">
                            <div class="dos-info-field1-in-dp">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-12 dos-info-field1-col1-dp">
                                    <div class="dos-info-field-label-dp">OBJET <strong>*</strong></div>
                                    <div class="dos-info-field-input-dp">
                                        <input type="text" v-model="objet" placeholder="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dos-info-switch-dp">
                            <div class="dos-info-switch-in-dp">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" v-model="notif" class="custom-control-input"
                                        id="customSwitch1">
                                    <label class="custom-control-label" for="customSwitch1">NOTIFICATION DE
                                        L’ASSURÉ</label>
                                </div>
                            </div>
                        </div>
                        <div class="default-editor-block-dp default-editor-padding-dp">
                            <div class="notifi-Editor-options-dp">
                                <div class="notifi-Editor-option1-dp">
                                    <a href="javascript:void(0);" id="openDisordersInsert"
                                        @click="openDisordersInsert()"><i class="far fa-house-crack"></i></a>
                                </div>
                                <div class="notifi-Editor-option1-dp">
                                    <a href="javascript:void(0);" id="openWeftsInsert" @click="openWeftsInsert()"><i
                                            class="far fa-file-lines"></i></a>
                                </div>
                            </div>
                            <QuillEditor ref="editor" v-model:content="content" contentType="html" />
                            <div class="errorForm" v-if="errorForm">Champs manquants !</div>
                        </div>
                        <!-- <div class="default-files-list-dp">
							<div class="file-progress-app" v-for="(file,key) of files" :key="key">
								<div class="file-progress-left-app">
									<i class="far fa-file-pdf"></i>
								</div>
								<div class="file-progress-right-app ">
									<div class="file-progress-top-app">
										<div class="file-progress-top-left-app">
											<div class="file-progress-file-name-app">{{file.name}}</div>
										</div>
										<div class="file-progress-top-right-app">
											<div @click="deleteFile(key)" class="file-progress-cancel-app"><a href="#"><i class="far fa-times-circle"></i></a></div>
										</div>
									</div>
								</div>
								<div class="clearfix"></div>
							</div>
						</div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="rcd-info-bot-bar-rcp">
            <div class="rcd-info-bot-left-rcp">
                <div class="cancel-btn-main-hp"><a @click="closeAll()" href="#"
                        class="btn default-btnH40-aehp default-Gray-border-btn-aehp">Annuler</a></div>
            </div>
            <div class="rcd-info-bot-right-rcp">
                <input style="display:none" type="file" id="fileOrder" @change="previewFiles">
                <!-- <div class="join-btn-main-hp"  v-on:click="triggerFile"><a class="btn default-btnH40-aehp default-Gray-border-btn-aehp"><i class="far fa-paperclip-vertical"></i>Joindre</a></div> -->
                <div class="confirm-btn-main-hp"><a href="#" @click="generatePdf()" class="btn default-btnH40-aehp"><i
                            class="far fa-file-pdf"></i>Générer le pdf</a></div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <!-- NOTIFICATIONS_END -->


    <!-- DISORDERS_INSERT_START -->
    <div class="Ndisor-insert-information-dp" id="Disorders_Insert_dp">
        <div class="Ndisor-close-dp">
            <a href="javascript:void(0)" v-on:click="closeDisorders()"><i class="fal fa-times"></i></a>
        </div>
        <div class="rcd-info-box-rcp optiscroll">
            <div class="rcd-info-box-in-rcp">
                <div class="rcd-info-box-pad-rcp">
                    <div class="rcd-title-rcp">
                        <h2>Désordres à insérer</h2>
                    </div>
                    <div class="rdc-speaker-rcp">
                        <div class="Dorders-insert-list-dp">
                            <div class="Dorders-insert-box1-dp custom-control custom-radio" style="min-width: 100%"
                                v-for="(disorder, key) in disorders" :key="key">
                                <input type="checkbox" class="custom-control-input disorderInput"
                                    :name="'Dorders_insert_' + key" :id="'Dorders_insert_' + key">
                                <label class="custom-control-label" :id="'Dorders_insert_label_' + key"
                                    :for="'Dorders_insert_' + key" style="min-width: 100%">
                                    <div class="Dorders-insert-box1-tit-dp">
                                        <h2><strong>{{ key + 1 }}. {{ translator(disorder.id_type_disorder.name)
                                                }}</strong>
                                        </h2><br>
                                    </div>
                                    <div class="Dorders-insert-box1-text-dp" v-if="disorder.location">
                                        <p><span style="font-weight: 700"><strong>Localisation :
                                                </strong></span>{{ disorder.location }}</p>
                                    </div>
                                    <div class="Dorders-insert-box1-text-dp" v-if="disorder.importance">
                                        <p><span style="font-weight: 700"><strong>Importance :
                                                </strong></span>{{ disorder.importance }}</p>
                                    </div>
                                    <div class="Dorders-insert-box1-text-dp" v-if="disorder.engenders_infiltration">
                                        <p><span style="font-weight: 700"><strong>Engendrent-elles des infiltrations ?
                                                </strong></span>{{ disorder.engenders_infiltration ? 'Oui' : 'Non' }}
                                        </p>
                                    </div>
                                    <div class="Dorders-insert-box1-text-dp" v-if="disorder.material_fall_risk">
                                        <p><span style="font-weight: 700"><strong>Engendrent-elles un risque de chute de
                                                    matériaux ? </strong> </span>{{ disorder.material_fall_risk ? 'Oui'
                                                        :
                                                        'Non' }}</p>
                                    </div>
                                    <div class="Dorders-insert-box1-text-dp" v-if="disorder.origin">
                                        <p><span style="font-weight: 700"><strong>L'origine est-elle apparante ?
                                                </strong></span>{{ disorder.origin ? 'Oui' : 'Non' }}</p>
                                    </div>
                                    <div class="Dorders-insert-box1-text-dp" v-if="disorder.description !== ''">
                                        <h3><strong>Description</strong></h3>
                                        <p>{{ disorder.description }}</p>
                                    </div>
                                    <br>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rcd-info-bot-bar-rcp">
            <div class="rcd-info-bot-left-rcp">
                <div class="cancel-btn-main-hp ins-disorder-btn-main-hp"><a href="#"
                        class="btn default-btnH40-aehp default-Bborder-btn-aehp" v-on:click="addDisorders"><i
                            class="far fa-arrow-right"></i>Insérer les désordres sélectionnés</a></div>
                <div class="cancel-btn-main-hp"><a href="#" class="btn default-btnH40-aehp default-Gray-border-btn-aehp"
                        v-on:click="closeDisorders()">Annuler</a></div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <!-- DISORDERS_INSERT_END -->

    <!-- WEFTS_INSERT_START -->
    <div class="Ndisor-insert-information-dp" id="Wefts_Insert_dp">
        <div class="Ndisor-close-dp">
            <a href="javascript:void(0)" v-on:click="closeWefts()"><i class="fal fa-times"></i></a>
        </div>
        <div class="rcd-info-box-rcp Winse-info-box-dp optiscroll">
            <div class="rcd-info-box-in-rcp">
                <div class="rcd-info-box-pad-rcp">
                    <div class="rcd-title-rcp">
                        <h2>Trames à insérer</h2>
                    </div>
                    <div class="rdc-speaker-rcp">
                        <div class="Wefts-insert-list-dp">
                            <div class="Wefts-insert-box1-dp" v-for="(weft, key) in wefts" :key="key">
                                <div class="Wefts-insert-box1-title-dp">
                                    <h2>{{ weft.title }}</h2>
                                    <div class="Wefts-insert-box1-btn-dp"><a href="#"
                                            class="btn default-btnH40-aehp default-Bborder-btn-aehp"
                                            @click="addContent(weft.content)">Insérer dans le texte</a></div>
                                    <div class="clearfix"></div>
                                </div>
                                <div class="Wefts-insert-box1-text-dp" v-html="weft.content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- WEFTS_INSERT_END -->
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import $ from 'jquery';
import vuecookie from 'vue-cookie'

export default {
    name: 'Notifications',
    components: {
        QuillEditor,
    },
    data() {
        return {
            vref: null,
            numrar: null,
            objet: null,
            notif: false,
            wefts: 'toto',
            disorders: null,
            content: '',
            files: [],
            errorForm: false,
            loader: false
        }
    },
    props: ['idSinister'],
    beforeMount() {
        this.$store.dispatch('notifications/find', { id: this.idSinister })
            .then((res) => {
                this.wefts = res.findWefts;
                this.disorders = res.findDisorders;
            });
    },
    mounted() {
        $("#Notifications_sidebar_rcp").toggleClass("open");
    },

    methods: {
        generatePdf() {
            if (!this.vref || !this.objet || this.content === '') {
                this.errorForm = true;
            }
            else {
                this.loader = true;
                const token = vuecookie.get('jwt');
                this.$store.dispatch('notifications/generate', { editorContent: this.content, object: this.objet, vref: this.vref, token, id: this.idSinister, notif: this.notif })
                    .then(() => {
                        this.$emit('successMessage');
                        this.closeAll();
                    })
                    .finally(() => {
                        this.loader = false;
                    });
            }
        },
        openDisordersInsert() {
            $('#openDisordersInsert').toggleClass("active");
            $("#Disorders_Insert_dp").toggleClass("open");
            $("#Wefts_Insert_dp").removeClass("open");
            $("#openWeftsInsert").removeClass("active");
        },
        openWeftsInsert() {
            $('#openWeftsInsert').toggleClass("active");
            $("#Wefts_Insert_dp").toggleClass("open");
            $("#Disorders_Insert_dp").removeClass("open");
            $("#openDisordersInsert").removeClass("active");
        },
        closeDisorders() {
            $('#openDisordersInsert').removeClass("active");
            $("#Disorders_Insert_dp").removeClass("open");
        },
        closeWefts() {
            $('#openWeftsInsert').removeClass("active");
            $("#Wefts_Insert_dp").removeClass("open");
        },
        closeAll() {
            $(".rcd-overlay-rcp").fadeOut();
            $("#request_proof_dp").removeClass("open");
            $("#Process_sav_rcp").removeClass("open");
            $("#Mission_order_rcp").removeClass("open");
            $("#Notifications_sidebar_rcp").removeClass("open");
            $("#documents_rcp").removeClass("open");
            $('#openDisordersInsert').removeClass("active");
            $("#Disorders_Insert_dp").removeClass("open");
            $('#openWeftsInsert').removeClass("active");
            $("#Wefts_Insert_dp").removeClass("open");
            $("#documents_rcp").removeClass("open");
            this.$emit('close');
        },
        addContent(contentAdd) {
            this.content = this.content + contentAdd;
        },
        translator(data) {
            return this.$store.getters['translator/translate'](data)
        },
        addDisorders() {
            var that = this;
            $('.disorderInput').each(function () {
                const value = $(this).is(":checked");
                if (value) {
                    let content = $(this).next().html();
                    content = content.toString();
                    content = content.replace(/<div[^>]*>(.*?)<\/div>/gs, '$1');
                    content = content.replace(/<!--.*?-->/g, '');
                    that.addContent(content);
				}
			})
		},
		triggerFile(){
            $('#fileOrder').click();
        },
        previewFiles() {
			console.log('test');
			const input = document.querySelector("#fileOrder");
			let file = input.files[0];
            const images = this.files;
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                images.push({name : file.name, state : '0', buffer : reader.result, type : file.type.split('/')[1]});
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
			console.log(images);
        },
		deleteFile(index){
            this.files.splice(index, 1);
            document.querySelector("#fileOrder").value = "";
        },
		
	}
    
}
</script>

<style scoped>
.errorForm{
	color: red;
	margin-top: 15px;
}
.loader{
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>