<template>
    <div class="wrapper">
        <!-- CONTAIN_START -->
        <section id="contain">
            <div class="sidebar-main-dbp">
                <div class="logo-main-dbp">
                    <a href="#" v-on:click="this.$router.push('/gestionnaire')"><img alt=""
                            src="~@/assets/images/logo_main_hp.svg" /></a>
                </div>
                <div class="sidebar-info-box-dbp">
                    <div class="board-dmenu-dbp dropdown">
                        <a id="dropdownMenuLink" aria-expanded="false" aria-haspopup="true" class="btn dropdown-toggle"
                            data-toggle="dropdown" href="#" role="button">
                            <div class="board-menu-logo-dbp">
                                <img alt="" :src="getLogo" />
                            </div>
                            <div class="board-menu-text-dbp">
                                <div class="board-mtagline-dbp">Mandant</div>
                                <div class="board-mname-dbp">{{ getCompany }}</div>
                            </div>
                        </a>
                        <div aria-labelledby="dropdownMenuLink" class="dropdown-menu">
                            <ul>
                                <li>
                                    <a class="dropdown-item" href="javascript:void(0)" @click="updateCompany('QBE')">
                                        <div class="board-menu-logo-dbp"><img src="~@/assets/images/QBE_logo_aedecp.svg"
                                                alt=""></div>
                                        <div class="board-menu-text-dbp">
                                            <div class="board-mtagline-dbp">Mandant</div>
                                            <div class="board-mname-dbp">QBE</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:void(0)" @click="updateCompany('ERGO')">
                                        <div class="board-menu-logo-dbp"><img src="~@/assets/images/ergo_logo.svg"
                                                alt=""></div>
                                        <div class="board-menu-text-dbp">
                                            <div class="board-mtagline-dbp">Mandant</div>
                                            <div class="board-mname-dbp">ERGO</div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="testScroll" class="sidebar-nav-dbp optiscroll">
                        <div class="sidebar-nav-in-dbp">
                            <div class="sidebar-menu1-dbp">
                                <div class="sidebar-menu1-tit-dbp">FLUX</div>
                                <div id="cssmenu" class="sidebar-menu-list-dbp">
                                    <ul>
                                        <li :class="!page ? 'active' : 'error'"
                                            v-on:click="this.$router.push('/gestionnaire')">
                                            <a href="#">
                                                <div class="sidebar-menu-icon-dbp">
                                                    <i class="far fa-tachometer-alt-slowest"></i>
                                                </div>
                                                <div class="sidebar-menu-text-dbp">Tableau de bord</div>
                                                <div class="clearfix"></div>
                                            </a>
                                        </li>
                                        <li :class="page === 'sinistres' ? 'active' : 'error'"
                                            v-on:click="this.$router.push('/gestionnaire/sinistres')">
                                            <a href="#">
                                                <div class="sidebar-menu-icon-dbp">
                                                    <i class="far fa-folders"></i>
                                                    <!-- <div class="notifi-menu-number-dbp">3</div> -->
                                                </div>
                                                <div class="sidebar-menu-text-dbp">
                                                    Liste des dossiers
                                                    <!-- <div class="notifi-menu-number-dbp">3</div> -->
                                                </div>
                                                <div class="clearfix"></div>
                                            </a>
                                            <ul>
                                                <li class="active-in-menu-dbp">
                                                    <a href="#">Actifs (12) <span>3</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">D.O. (4) <span>1</span></a>
                                                </li>
                                                <li>
                                                    <a href="#">R.C.D. (8) <span>2</span></a>
                                                </li>
                                                <li><a href="#">Archivés (235)</a></li>
                                            </ul>
                                        </li>
                                        <li v-if="role === 0" :class="page === 'settings' ? 'active' : 'error'"
                                            v-on:click="this.$router.push('/gestionnaire/settings')">
                                            <a href="#">
                                                <div class="sidebar-menu-icon-dbp">
                                                    <i class="far fa-gear"></i>
                                                </div>
                                                <div class="sidebar-menu-text-dbp">Paramètres</div>
                                                <div class="clearfix"></div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div v-if="role === 0" class="sidebar-menu1-dbp">
                                <div class="sidebar-menu1-tit-dbp">GESTION</div>
                                <div id="cssmenu" class="sidebar-menu-list-dbp">
                                    <ul>
                                        <li :class="page === 'users' ? 'active' : 'error'"
                                            v-on:click="this.$router.push('/gestionnaire/users')">
                                            <a href="#">
                                                <div class="sidebar-menu-icon-dbp">
                                                    <i class="far fa-user-cog"></i>
                                                </div>
                                                <div class="sidebar-menu-text-dbp">Utilisateurs</div>
                                                <div class="clearfix"></div>
                                            </a>
                                        </li>
                                        <li :class="page === 'experts' ? 'active' : 'error'"
                                            v-on:click="this.$router.push('/gestionnaire/experts')">
                                            <a href="#">
                                                <div class="sidebar-menu-icon-dbp">
                                                    <i class="far fa-address-book"></i>
                                                </div>
                                                <div class="sidebar-menu-text-dbp">Experts</div>
                                                <div class="clearfix"></div>
                                            </a>
                                        </li>
                                        <li :class="page === 'trames' ? 'active' : 'error'">
                                            <a href="#" v-on:click="this.$router.push('/gestionnaire/trames')">
                                                <div class="sidebar-menu-icon-dbp">
                                                    <i class="far fa-envelopes-bulk"></i>
                                                </div>
                                                <div class="sidebar-menu-text-dbp">Trames</div>
                                                <div class="clearfix"></div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sidebar-bottom-dbp">
                    <div class="create-claim-btn-dbp">
                        <a class="btn default-btnH48-aehp" href="#" data-toggle="modal"
                            data-target="#createSinisterModal">
                            <i class="far fa-plus"></i>Créer un sinistre
                        </a>
                    </div>
                    <div class="reduce-btn-dbp">
                        <a id="reduce_btn" href="javascript:void(0);" @click="reduceMenu"><i
                                class="fal fa-align-justify"></i>Réduire</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 content-main-dbp">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12 top-bar-main-dbp">
                    <div class="logo-main-mob-dbp">
                        <a href="#"><img alt="" src="~@/assets/images/logo_main_hp.svg" /></a>
                    </div>
                    <div class="tbar-info-dbp">
                        <p>{{ date }}</p>
                    </div>
                    <div class="tbar-right-dbp">
                        <div class="tbar-search-main-dbp">
                            <Search />
                        </div>
                        <div class="user-dmenu-dbp dropdown">
                            <a id="dropdownMenuLink" aria-expanded="false" aria-haspopup="true"
                                class="btn dropdown-toggle" data-toggle="dropdown" href="#" role="button">
                                <div class="user-dmenu-logo-dbp">{{ setInitial }}</div>
                                <div class="user-dmenu-name-dbp">{{ setName }}</div>
                                <div class="user-dmenu-arrow-dbp">
                                    <i class="far fa-chevron-down"></i>
                                </div>
                            </a>
                            <div aria-labelledby="dropdownMenuLink" class="dropdown-menu dropdown-menu-right">
                                <ul>
                                    <li>
                                        <a class="dropdown-item" href="#" v-on:click="logOut"><i
                                                class="far fa-sign-out"></i>Déconnexion</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="mobile-menu-icon-dbp">
                            <div class="menu-toggle-btn-hp">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <Dashboard v-if="!page" />
                <Users v-if="page === 'users' && role === 0" :key="updateUser"
                    v-on:updateUsers="updateUser = updateUser + 1" />
                <ListingV2 v-if="page === 'sinistres'" :filterDashboard="filter" />
                <Settings v-if="page === 'settings'" />
                <SinisterDetails v-if="page === 'sinistre'" :key="id" :idSinister="id" />
                <Experts v-if="page === 'experts' && role === 0" :idSinister="id" />
                <Trames v-if="page === 'trames' && role === 0" :key="updateTrameKey"
                    v-on:update="updateTrameKey = updateTrameKey + 1" />
                <!-- <ListingV2 v-if="page === 'test'"/> -->
            </div>
            <div class="clearfix"></div>
        </section>
        <!-- CONTAIN_END -->
    </div>

    <div class="modal fade" id="createSinisterModal" tabindex="-1" aria-labelledby="" aria-hidden="true">
        <div class="modal-dialog modal-dialog-mp modal-dialog-centered">
            <div class="modal-content modal-content-mp confirm-modal-content-mp">
                <div class="modal-body modal-body-mp">
                    <div class="modal-title-mp">
                        <div class="common-title-main-aehp common-title-center-aehp">
                            <h2>Déclarer un sinistre</h2>
                        </div>
                    </div>
                    <div class="modal-details-mp">
                        <div id='contractInput' class="conn-form-field1-aelp">
                            <div class="conn-form-field1-tit-aelp">
                                Numéro de contrat
                            </div>
                            <div class="conn-form-field1-tbox-aelp">
                                <input v-model="contractNumber" placeholder="" type="text" :disabled="loader">
                            </div>
                            <div class="conn-form-error-message-aelp"><i
                                    class="fa-solid fa-triangle-exclamation"></i>Veuillez renseigner une référence
                                valide</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer-mp">
                    <div class="loader" style="text-align:center" v-if="loader">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div v-else class="conn-form-submit-btn-aelp"><button class="btn default-btn-aehp"
                            @click="find">Créer un
                            sinistre</button>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Dashboard from "@/components/administrator/Dashboard.vue";
import Users from "@/components/administrator/Users.vue";
import ListingV2 from "@/components/administrator/ListingV2.vue";
import SinisterDetails from "@/components/administrator/SinisterDetails.vue";
import Experts from "@/components/administrator/Experts.vue";
import Trames from "@/components/administrator/Trames.vue";
import Search from "@/components/search/Search.vue";
import Optiscroll from "optiscroll";
import $ from "jquery";
import vueCookie from "vue-cookie";
import Settings from "@/components/administrator/Settings.vue";
import { mapActions, mapGetters } from "vuex";
import QBELogo from '@/assets/images/QBE_logo_aedecp.svg';
import ERGOLogo from '@/assets/images/ergo_logo.svg';

export default {
    name: "Administrator",
    data() {
        return {
            date: null,
            compteurDate: null,
            pageName: null,
            updateUser: 0,
            updateTrameKey: 0,
            logoMap: {
                'QBE': QBELogo,
                'ERGO': ERGOLogo,
            },
            contractNumber: null,
            loader: false,
        };
    },
    props: ["page", "id", "filter"],
    components: {
        Settings,
        Dashboard,
        Users,
        Trames,
        Experts,
        SinisterDetails,
        ListingV2,
        Search,
    },
    computed: {
        ...mapGetters('dashboardAdmin', ['getCompany']),
        setName() {
            return localStorage.getItem("name");
        },
        setInitial() {
            let name = localStorage.getItem("name");
            let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

            let initials = [...name.matchAll(rgx)] || [];

            initials = (
                (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
            ).toUpperCase();

            return initials;
        },
        role() {
            const token = vueCookie.get("jwt");
            var base64Url = token.split(".")[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map(function (c) {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join(""),
            );
            console.log(JSON.parse(jsonPayload).user_role);
            return JSON.parse(jsonPayload).user_role;
        },
        getLogo() {
            return this.logoMap[this.getCompany];
        }
    },
    mounted() {
        this.getDate();
        this.pageName = this.page;
        const element = document.getElementById("testScroll");
        new Optiscroll(element);
    },
    methods: {
        ...mapActions('dashboardAdmin', ['updateCompany']),
        ...mapActions('createSinistre', ['reset']),
        ...mapActions('contract', ['findContract']),        
        getDate() {
            this.compteurDate = setInterval(() => {
                this.date = moment().format("dddd DD MMMM yyyy - HH:mm");
            }, 1000);
        },
        logOut() {
            localStorage.removeItem("name");
            vueCookie.delete("jwt");
            this.$router.push("/gestionnaire/login");
        },
        reduceMenu() {
            $("body").toggleClass("reduce-sidebar-dbp");
            var widthWindow = $(window).width();
            if (widthWindow <= 1439) {
                $("body").removeClass("reduce-sidebar-dbp");
                $("body").toggleClass("reduce-sidebar-mob-dbp");
            }
        },
        openSearch() {
            $(".tbar-search-main-dbp").toggleClass("search-bar-open-dbp");
        },
        find() {
            if (this.contractNumber) {
                this.loader = true;
                this.findContract(this.contractNumber)
                    .then(() => {
                        $('#createSinisterModal').modal('hide');
                        $('#contractInput').removeClass('conn-form-error-aelp');
                        this.reset();
                        this.$router.push('/gestionnaire/sinistre/nouveau')
                    })
                    .catch(() => {
                        $('#contractInput').toggleClass('conn-form-error-aelp')
                    }).finally(() => {
                        this.loader = false;
                    });
            }
            else {
                $('#createSinisterModal').modal('hide');
                this.reset();
                this.$router.push('/gestionnaire/sinistre/nouveau')
            }
        }
  },
};
</script>
