<template>
    <div class="col-lg-6 col-md-6 col-sm-12 col-12 connexion-right-aelp">
        <div class="connexion-right-in-aelp">
            <div class="connexion-box-main-aelp">
                <div class="common-title-main-aehp common-title-center-aehp">
                    <h2>Consulter un sinistre</h2>
                    <p>Munissez-vous du code qui vous a été envoyé par mail, ainsi que de votre référence sinistre.</p>
                </div>
                <div class="connexion-form-aelp">
                    <div class="conn-form-field1-aelp" id="refInput">
                        <div class="conn-form-field1-tit-aelp">Référence sinistre</div>
                        <div class="conn-form-field1-tbox-aelp">
                            <input v-model="ref" type="text" placeholder="">
                        </div>
                        <div class="conn-form-error-message-aelp"><i
                                class="fa-solid fa-triangle-exclamation"></i>Veuillez
                            renseigner une référence valide
                        </div>
                    </div>
                    <div class="conn-form-field1-aelp" id="passwordInput">
                        <div class="conn-form-field1-tit-aelp">Mot de passe</div>
                        <div class="conn-form-field1-tbox-aelp" style="position: relative;">
                            <input id="password-field" v-model="password" :type="passwordInputType" placeholder=""
                                style="padding-right: 55px;">
                            <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                            <!-- <button style="position: absolute; right: 15px; top: 50%; transform: translateY(-50%); border: none; background-color: transparent; " @click="togglePasswordInputType()">
                <i v-if="!showPassword" class="far fa-eye"></i>
                <i v-if="showPassword" class="far fa-eye-slash"></i>
              </button> -->
                        </div>
                        <div class="conn-form-error-message-aelp"><i
                                class="fa-solid fa-triangle-exclamation"></i>Veuillez
                            renseigner un mot de passe valide
                        </div>
                    </div>
                    <div class="conn-form-forgot-link-aelp">
                        <a href="#" data-toggle="modal" data-target="#notifiDeleteModal">Mot de passe oublié ?</a>
                    </div>
                    <div class="conn-form-submit-btn-aelp">
                        <button type="submit" v-on:click="find" class="btn default-btn-aehp">Se connecter</button>
                    </div>
                </div>
            </div>
            <!-- FORGET_MODAL_START -->
            <div class="modal fade" id="notifiDeleteModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-mp modal-dialog-centered">
                    <div class="modal-content modal-content-mp"> <!-- cancel-modal-content-mp -->
                        <div class="modal-body modal-body-mp">
                            <div class="modal-title-mp">
                                <div class="modal-title-icon-mp">
                                    <div class="modal-title-icon-in-mp">
                                        <i class="far fa-key"></i>
                                    </div>
                                </div>
                                <div class="modal-title-text-mp">Vous avez oublié votre mot de passe ?</div>
                            </div>
                            <div class="modal-details-mp">
                                <p>Veuillez renseigner votre adresse email ainsi que la référence du sinistre.</p>
                                <div class="conn-form-field1-aelp" id="refInput">
                                    <div class="conn-form-field1-tit-aelp">Adresse email</div>
                                    <div class="conn-form-field1-tbox-aelp">
                                        <input v-model="forget.email" type="text" placeholder="">
                                    </div>
                                    <div class="conn-form-error-message-aelp"><i
                                            class="fa-solid fa-triangle-exclamation"></i>Veuillez
                                        renseigner une référence valide
                                    </div>
                                </div>
                                <div class="conn-form-field1-aelp" id="refInput">
                                    <div class="conn-form-field1-tit-aelp">Référence sinistre</div>
                                    <div class="conn-form-field1-tbox-aelp">
                                        <input v-model="forget.ref" type="text" placeholder="">
                                    </div>
                                    <div class="conn-form-error-message-aelp"><i
                                            class="fa-solid fa-triangle-exclamation"></i>Veuillez
                                        renseigner une référence valide
                                    </div>
                                    <p style="margin-top: 10px;">
                                        Vous n’avez pas votre numéro de sinistre ? <a
                                            href="https://www.agemi.net/contact">Nous contacter</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer-mp">
                            <div class="cancel-btn-main-hp"><a href="javascript:void(0)" data-dismiss="modal"
                                    aria-label="Close"
                                    class="btn default-btnH40-aehp default-blue-border-btn-aehp">Annuler</a>
                            </div>
                            <div class="confirm-btn-main-hp"><a v-on:click="testEmail()" data-dismiss="modal"
                                    class="btn default-btnH40-aehp default-blue-btn-aehp"><i
                                        class="far fa-envelope"></i>Recevoir le nouveau mot de passe</a></div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- FORGET_MODAL_END -->
            <!-- ERROR_MODAL_START -->
            <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="" aria-hidden="true">
                <div class="modal-dialog modal-dialog-mp modal-dialog-centered">
                    <div class="modal-content modal-content-mp cancel-modal-content-mp">
                        <!-- cancel-modal-content-mp -->
                        <div class="modal-body modal-body-mp">
                            <div class="modal-title-mp">
                                <div class="modal-title-icon-mp">
                                    <div class="modal-title-icon-in-mp">
                                        <i class="far fa-exclamation-circle"></i>
                                    </div>
                                </div>
                                <div class="modal-title-text-mp">Identifiants incorrects</div>
                            </div>
                            <div class="modal-details-mp">
                                <p>Les indentifiants renseignés ne sont pas bon.</p>
                            </div>
                        </div>
                        <div class="modal-footer-mp">
                            <div class="cancel-btn-main-hp"><a href="javascript:void(0)" data-dismiss="modal"
                                    aria-label="Close" class="btn default-btnH40-aehp default-red-btn-aehp">Fermer</a>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ERROR_MODAL_END -->
            <!-- SUCCESS_MODAL_START -->
            <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="" aria-hidden="true">
                <div class="modal-dialog modal-dialog-mp modal-dialog-centered">
                    <div class="modal-content modal-content-mp confirm-modal-content-mp">
                        <!-- cancel-modal-content-mp -->
                        <div class="modal-body modal-body-mp">
                            <div class="modal-title-mp">
                                <div class="modal-title-icon-mp">
                                    <div class="modal-title-icon-in-mp">
                                        <i class="far fa-check-circle"></i>
                                    </div>
                                </div>
                                <div class="modal-title-text-mp">Un nouveau mot de passe a été généré</div>
                            </div>
                            <div class="modal-details-mp">
                                <p>Vous allez recevoir un email contenant le nouveau mot de passe dans quelques minutes.
                                    N’oubliez pas de vérifier vos courriers indésirables.</p>
                            </div>
                        </div>
                        <div class="modal-footer-mp">
                            <div class="cancel-btn-main-hp"><a href="javascript:void(0)" data-dismiss="modal"
                                    aria-label="Close" class="btn default-btnH40-aehp default-green-btn-aehp">Fermer</a>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!--  SUCCESS_MODAL_END -->
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import $ from 'jquery';

export default {
  name: 'FindSinister',
  data() {
    return {
      ref: '',
      password: "",
      showPassword: false,
      forget: {
          ref: '',
          email: ''
      }
    }
  },
    computed: {
        passwordInputType() {
            return this.showPassword ? 'text' : 'password';
        }
    },
    mounted() {
        $(".toggle-password").click(function() {
            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $($(this).attr("toggle"));
            if (input.attr("type") == "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });
    },
  methods: {
    ...mapActions('findSinister', ['findSinister']),
    find() {
      if (!this.ref) {
        document.getElementById('refInput').classList.add('conn-form-error-aelp')
      } else {
        document.getElementById('refInput').classList.remove('conn-form-error-aelp')
      }
      if (!this.password) {
        document.getElementById('passwordInput').classList.add('conn-form-error-aelp')
      } else {
        document.getElementById('passwordInput').classList.remove('conn-form-error-aelp')
      }
      if (this.ref && this.password) {
        this.findSinister({ref: this.ref, password: this.password})
            .then(() => {
              this.$router.push('/assure/dashboard')
            })
            .catch(() => {
              document.getElementById('passwordInput').classList.add('conn-form-error-aelp');
              document.getElementById('refInput').classList.add('conn-form-error-aelp');
            })
      }
    },
    testEmail() {
      this.$store.dispatch('forgetPassword/testEmail', this.forget)
          .then(() => {
            $('#successModal').modal('show');
          })
          .catch(() => {
            $('#errorModal').modal('show');
          })
    },
    //togglePasswordInputType() {
    //  this.showPassword = !this.showPassword;
    //}
  }
}

</script>
