<script>
import server from "@/api/server";
import { mapGetters } from "vuex";

export default {
    name: "Settings",
    data() {
        return {
            settings: {
                id: null,
                storage_method: null,
            },
        };
    },
    computed: {
        ...mapGetters('dashboardAdmin', ['getCompany'])
    },
    watch: {
        getCompany() {
            this.getSettings()
        }
    },
    mounted() {
        this.getSettings();
    },
    methods: {
        getSettings() {
            server
                .get("/administrator/settings/find", { params: { company: this.getCompany } })
                .then((response) => {
                    this.settings = response.data;
                }).catch(() => {
                    this.settings = {
                        id: null,
                        storage_method: null,
                    };
                });
        },
        saveSettings() {
            server
                .post("/administrator/settings/update", {
                    ...this.settings,
                    company: this.getCompany,
                })
                .then((response) => {
                    console.log(response.data);
                });
        },
    },
};
</script>

<template>
    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="content-title-dbp">
            <h2 class="title-dbp">Paramètres de stockage</h2>
        </div>

        <div class="content-in-dbp">
            <div class="content-in-sub-dbp">
                <div class="content-box-dbp">
                    <form @submit.prevent="saveSettings">
                        <div class="form-group">
                            <div class="custom-control custom-radio">
                                <input type="radio" id="sharepoint" value="sharepoint" v-model="settings.storage_method"
                                    class="custom-control-input">
                                <label class="custom-control-label" for="sharepoint">Sharepoint</label>
                            </div>

                            <div class="custom-control custom-radio">
                                <input type="radio" id="winpass" value="winpass" v-model="settings.storage_method"
                                    class="custom-control-input">
                                <label class="custom-control-label" for="winpass">Winpass</label>
                            </div>
                        </div>

                        <div class="form-group">
                            <button type="submit" class="btn default-btnH48-aehp">
                                Enregistrer
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.content-title-dbp {
    margin-bottom: 30px;
}

.title-dbp {
    color: #3C3C3C;
    font-size: 28px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.content-in-dbp {
    padding: 20px;
}

.content-in-sub-dbp {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.content-box-dbp {
    padding: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.custom-control {
    margin: 10px 0;
}

.custom-control-input {
    margin-right: 10px;
}

.custom-control-label {
    color: #3C3C3C;
    font-size: 16px;
}

.default-btnH48-aehp {
    background: #009AE4;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.2s;
}

.default-btnH48-aehp:hover {
    background: #0088cc;
}

.default-btnH48-aehp i {
    margin-right: 8px;
}
</style>
