<template>
    <div class="declaring-block-main-aedecp optiscroll stepThree">
        <div class="declaring-block-in-aedecp">
            <div class="common-title-main-aehp common-title-left-aehp">
                <h2>Renseignements relatifs à la construction</h2>
            </div>
            <div class="declaring-form-main-aedecp">
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">Nature de l’opération</div>
                            <div class="defa-option-list-aedecp">
                                <div class="defa-option1-aedecp">
                                    <div class="custom-control custom-radio">
                                        <input v-model="construction_information.nature_operation" type="radio"
                                            id="default_option1" value="apartment" name="default-option1"
                                            class="custom-control-input">
                                        <label class="custom-control-label" for="default_option1">Immeuble</label>
                                    </div>
                                </div>
                                <div class="defa-option1-aedecp">
                                    <div class="custom-control custom-radio">
                                        <input v-model="construction_information.nature_operation" type="radio"
                                            id="default_option2" value="house" name="default-option1"
                                            class="custom-control-input">
                                        <label class="custom-control-label" for="default_option2">Maison
                                            individuelle</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">Adresse de la construction sinistrée</div>
                            <div class="default-sub-form-row1-aedecp">
                                <div
                                    class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp add-field1-aedecp">
                                    <div class="default-form-field1-Stit-aedecp">Rue</div>
                                    <div class="defa-form-field-Tbox-aedecp">
                                        <input v-model="construction_information.street" @input="fetchPredictions"
                                            @keydown="onKeyDown" @change="(e) => remove(e.target)" type="text"
                                            placeholder="">
                                        <ul v-if="predictions.length" style="
                        position: absolute;
                        margin-top: 10px;
                        width: 100%;
                        background-color: white;
                        z-index: 50;
                        font-size: 16px;
                        box-shadow: var(
                          0 20px 25px -5px rgb(0 0 0 / 0.1),
                          0 8px 10px -6px rgb(0 0 0 / 0.1)
                        );
                        border: solid 2px #e4e8f4;
                        color: #434756;
                        border-radius: 4px;
                      ">
                                            <li v-for="prediction in predictions" :key="prediction.place_id"
                                                @click="selectAddress(prediction.place_id)"
                                                style="padding: 8px; cursor: pointer">
                                                {{ prediction.description }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="default-sub-form-row1-aedecp">
                                <div
                                    class="col-lg-7 col-md-7 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W65-aedecp">
                                    <div class="default-form-field1-Stit-aedecp">Ville</div>
                                    <div class="defa-form-field-Tbox-aedecp">
                                        <input v-model="construction_information.city" @change="(e) => remove(e.target)"
                                            type="text" placeholder="">
                                    </div>
                                </div>
                                <div
                                    class="col-lg-5 col-md-5 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W35-aedecp">
                                    <div class="default-form-field1-Stit-aedecp">Code postal</div>
                                    <div class="defa-form-field-Tbox-aedecp">
                                        <input v-model="construction_information.zipcode"
                                            @change="(e) => remove(e.target)" type="text" placeholder="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">Nom du constructeur</div>
                            <div class="defa-form-field-Tbox-aedecp">
                                <input v-model="construction_information.manufacturer_name"
                                    @change="(e) => remove(e.target)" type="text" placeholder="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">Nom du premier propriétaire</div>
                            <div class="defa-form-field-Tbox-aedecp">
                                <input v-model="construction_information.first_owner_name"
                                    @change="(e) => remove(e.target)" type="text" placeholder="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">Date règlementaire d’ouverture du chantier
                                (DROC)</div>
                            <div
                                class="defa-form-field-Tbox-aedecp defa-Ffield-W50-aedecp input-group defa-form-Dtpicker-aedecp date form_date">
                                <input :value="formatDate(construction_information.settlement_date_opening_site)"
                                    type="text" @change="(e) => remove(e.target)"
                                    @blur="(e) => onDateBlur(e, 'settlement_date_opening_site')"
                                    class="cal-size datepicker_hp" placeholder="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">Date de réception des travaux</div>
                            <div
                                class="defa-form-field-Tbox-aedecp defa-Ffield-W50-aedecp input-group defa-form-Dtpicker-aedecp date form_date">
                                <input :value="formatDate(construction_information.receipt_work_date)" type="text"
                                    @change="(e) => remove(e.target)" @blur="(e) => onDateBlur(e, 'receipt_work_date')"
                                    class="cal-size datepicker_hp2" placeholder="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">La réception a-t-elle fait l’objet de réserves ?
                            </div>
                            <div class="defa-option-list-aedecp">
                                <div class="defa-option1-aedecp">
                                    <div class="custom-control custom-radio">
                                        <input v-model="construction_information.reservations" type="radio"
                                            checked="true" :value="true" id="default_option3"
                                            name="reservations-option1" class="custom-control-input">
                                        <label class="custom-control-label" for="default_option3">Oui</label>
                                    </div>
                                </div>
                                <div class="defa-option1-aedecp">
                                    <div class="custom-control custom-radio">
                                        <input v-model="construction_information.reservations" :value="false"
                                            type="radio" id="default_option4" name="reservations-option1"
                                            class="custom-control-input">
                                        <label class="custom-control-label" for="default_option4">Non</label>
                                    </div>
                                </div>
                            </div>
                            <div class="default-note-text-aedecp">
                                <p>(Attention : à ne pas confondre avec la déclaration d’achèvement des travaux ou la
                                    date de livraison de l’ouvrage – La réception des travaux est actée par
                                    Procès-verbal de réception signé au contradictoire du constructeur)</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">Nom de l’occupant</div>
                            <div class="defa-form-field-Tbox-aedecp">
                                <input v-model="construction_information.occupant_name" type="text"
                                    @change="(e) => remove(e.target)" placeholder="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">L’occupant est</div>
                            <div class="defa-option-list-aedecp">
                                <div class="defa-option1-aedecp">
                                    <div class="custom-control custom-radio">
                                        <input v-model="construction_information.occupant_state" checked="true"
                                            value="owner" type="radio" id="default_option5" name="occupant-option1"
                                            class="custom-control-input">
                                        <label class="custom-control-label" for="default_option5">Propriétaire</label>
                                    </div>
                                </div>
                                <div class="defa-option1-aedecp">
                                    <div class="custom-control custom-radio">
                                        <input v-model="construction_information.occupant_state" value="tenant"
                                            type="radio" id="default_option6" name="occupant-option1"
                                            class="custom-control-input">
                                        <label class="custom-control-label" for="default_option6">Locataire</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-5 col-md-5 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W35-aedecp">
                            <div class="default-form-field1-tit-aedecp">N° de téléphone de l’occupant</div>
                            <div class="defa-form-field-Tbox-aedecp">
                                <input v-model="construction_information.occupant_tel" type="text"
                                    @change="(e) => remove(e.target)" placeholder="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="default-form-btn-bar-aedecp">
                <div class="default-form-btn-aedecp">
                    <a href="#" @click="previousStep" class="btn default-btn-aehp default-Bborder-btn-aehp">Etape
                        précédente</a>
                </div>
                <div class="default-form-btn-aedecp">
                    <a href="#" v-on:click="nextStep" class="btn default-btn-aehp">Continuer</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import $ from 'jquery';
import moment from 'moment';
import { debounce } from "lodash-es";

export default {
    name: 'StepThree',
    props: ['type'],
    components: {
    },
    data() {
        return {
            // construction_information : {
            //     nature_operation : 'apartment',
            //     street : null,
            //     city : null,
            //     zipcode : null,
            //     manufacturer_name : null,
            //     first_owner_name : null,
            //     settlement_date_opening_site : null,
            //     receipt_work_date : null,
            //     reservations : true,
            //     occupant_name : null,
            //     occupant_state : 'owner',
            //     occupant_tel : null
            // }
            construction_information: {
                nature_operation: 'apartment',
                street: '8 place Msgr Rumeaux',
                city: 'Angers',
                zipcode: '49100',
                manufacturer_name: 'Toto',
                first_owner_name: 'Gentilhomme',
                settlement_date_opening_site: '2020-01-11',
                receipt_work_date: '2020-01-11',
                reservations: true,
                occupant_name: 'Gentilhomme',
                occupant_state: 'owner',
                occupant_tel: '6049089812'
            },
            predictions: [],
            autocompleteService: null,
            selectedIndex: -1,
        }
    },
    computed: mapState({
        storeConstructionInformation: state => state.createSinistre.construction_information,
    }),
    beforeMount() {
        this.construction_information = { ...this.storeConstructionInformation }
    },
    mounted() {
        if (window.google) {
            this.autocompleteService = new google.maps.places.AutocompleteService();
        }
        $('.datepicker_hp').datepicker({
            uiLibrary: 'bootstrap4',
            iconsLibrary: 'fontawesome',
            format: "dd/mm/yyyy",
            language: "fr"
        }).on('changeDate', (e) => this.construction_information.settlement_date_opening_site = e.date);
        $('.datepicker_hp2').datepicker({
            uiLibrary: 'bootstrap4',
            iconsLibrary: 'fontawesome',
            format: "dd/mm/yyyy",
            language: "fr"
        }).on('changeDate', (e) => this.construction_information.receipt_work_date = e.date);
    },
    unmounted() {
        $('.datepicker_hp').datepicker('destroy');
        $('.datepicker_hp2').datepicker('destroy');
    },
    methods: {
        ...mapActions('createSinistre', ['addConstructionInformation']),
        previousStep() {
            this.addConstructionInformation(this.construction_information);
            this.$emit("click", { action: "StepTwo" });
        },
        nextStep() {
            if (this.type === 'assured') {
                let numberInputThree = 0;
                let numberGoodInput = 0;
                $(".stepThree input:text").each(function () {
                    numberInputThree++;
                    if (!$(this).val()) {
                        $(this).addClass('errorInput');
                    }
                    else {
                        numberGoodInput++
                        $(this).removeClass('errorInput');
                    }
                });
                if (numberInputThree === numberGoodInput) {
                    this.addConstructionInformation(this.construction_information);
                    this.$emit('click', { action: 'StepFour' });
                } else {
                    const firstErrorInput = document.querySelector('.errorInput');
                    console.log(firstErrorInput)
                    if (firstErrorInput) {
                        firstErrorInput.scrollIntoView({ behavior: 'smooth' });
                    }
                }
                console.log('numberInputThree', numberInputThree);
                console.log('numberGoodInput', numberGoodInput);
            }
            else {
                this.addConstructionInformation(this.construction_information);
                this.$emit('click', { action: 'StepFour' });
            }
        },
        remove(e) {
            $(e).removeClass('errorInput');
        },
        formatDate(date) {
            if (!date || !moment(date).isValid()) {
                return '';
            }
            return moment(date).format('DD/MM/YYYY');
        },
        onDateBlur(e, field) {
            const date = moment(e.target.value, 'DD/MM/YYYY');
            if (!date.isValid()) {
                this.construction_information[field] = null;
            }
            else {
                this.construction_information[field] = date.toDate();
            }
        },
        fetchPredictions: debounce(function () {
            if (!this.construction_information.street || !this.autocompleteService) {
                this.predictions = [];
                return;
            }

            this.autocompleteService.getPlacePredictions(
                {
                    input: this.construction_information.street,
                    types: ["address"],
                    componentRestrictions: { country: "fr" },
                },
                (suggestions) => {
                    this.predictions = suggestions || [];
                    this.selectedIndex = -1;
                },
            );
        }, 200),
        selectAddress(placeId) {
            const placesService = new google.maps.places.PlacesService(
                document.createElement("div"),
            );

            placesService.getDetails({ placeId }, (place) => {
                if (!place) return;

                this.construction_information.street = "";
                this.construction_information.city = "";
                this.construction_information.zipcode = "";

                place.address_components.forEach((component) => {
                    if (component.types.includes("street_number")) {
                        this.construction_information.street = component.long_name;
                    }
                    if (component.types.includes("route")) {
                        this.construction_information.street += " " + component.long_name;
                        this.construction_information.street = this.construction_information.street.trim();
                    }
                    if (component.types.includes("locality")) {
                        this.construction_information.city = component.long_name; // City
                    }
                    if (component.types.includes("postal_code")) {
                        this.construction_information.zipcode = component.long_name; // Postal Code
                    }
                });

                this.predictions = [];
            });
        },
        onKeyDown(e) {
            if (e.key === "ArrowDown") {
                this.selectedIndex = (this.selectedIndex + 1) % this.predictions.length;
            } else if (e.key === "ArrowUp") {
                this.selectedIndex =
                    this.selectedIndex <= 0
                        ? this.predictions.length - 1
                        : this.selectedIndex - 1;
            } else if (e.key === "Enter" && this.selectedIndex !== -1) {
                this.selectAddress(this.predictions[this.selectedIndex].place_id);
            }
        },
    }
}
</script>
<style scoped>
.errorInput {
    border: solid 2px #f03737;
}
</style>
