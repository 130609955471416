<template>
    <div class="wrapper">
        <!-- CONTAIN_START -->
        <section id="contain">
            <div class="sidebar-main-aedecp">
                <div class="logo-main-aedecp"><a href="#"><img alt="" src="~@/assets/images/logo_main_white_hp.svg"></a>
                </div>
                <div class="sidebar-content-aedecp optiscroll">
                    <div class="sidebar-content-in-aedecp">
                        <div class="decl-steps-main-aedecp">
                            <ul>
                                <li :class="{ 'current': stepNumber === 1, 'active': stepNumber > 1 }">
                                    <!-- Add Class FOR Current step "current" OR Add Class FOR Complete step "active" -->
                                    <a>Bienvenue</a>
                                </li>
                                <li :class="{ 'current': stepNumber === 2, 'active': stepNumber > 2 }">
                                    <a href="#" v-on:click="changeStep({ action: 'StepTwo' })">Déclarant</a>
                                </li>
                                <li :class="{ 'current': stepNumber === 3, 'active': stepNumber > 3 }">
                                    <a href="#" v-on:click="changeStep({ action: 'StepThree' })">Renseignements relatifs
                                        à la
                                        construction</a>
                                </li>
                                <li :class="{ 'current': stepNumber === 4, 'active': stepNumber > 4 }">
                                    <a href="#" v-on:click="changeStep({ action: 'StepFour' })">Renseignements relatifs
                                        au sinistre</a>
                                </li>
                                <li :class="{ 'current': stepNumber === 5, 'active': stepNumber > 5 }">
                                    <a href="#">Signature</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="declaration-close-aedecp">
                    <a v-on:click="this.$router.push('/assure')"><i class="fa-regular fa-xmark"></i>
                        <template v-if="stepNumber === 6">Quitter</template>
                        <template v-else>Quitter et abandonner la déclaration</template>
                    </a>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 content-main-aedecp">
                <div class="top-bar-main-aedecp">
                    <div class="tbar-left-aedecp">
                        <div class="logo-main-mobile-aedecp"><a v-on:click="backStep"><img alt=""
                                    src="~@/assets/images/logo_main_hp.svg"></a></div>
                        <div v-if="stepNumber < 6" class="tbar-back-main-aedecp"><a v-on:click="backStep"><i
                                    class="fa-regular fa-arrow-left"></i></a></div>
                        <div class="tbar-user-info-main-aedecp">
                            <div class="tbar-user-text-aedecp">
                                <h2>{{ this.$store.state.contract.produit }}</h2>
                                <p>Contrat n° <span>{{ this.$store.state.contract.contract_number }}</span></p>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="tbar-right-aedecp">
                        <div class="declaration-numbers-aedecp"><span>{{ stepNumber }}/</span>5</div>
                        <div class="mobile-menu-icon-aedecp">
                            <div class="menu-toggle-btn-aedecp">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div v-if="showLoader" class="loader">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <StepOne v-if="stepNumber === 1" type="assured" v-on:click="changeStep" />
                <StepTwo v-if="stepNumber === 2" type="assured" v-on:click="changeStep" />
                <StepThree v-if="stepNumber === 3" type="assured" v-on:click="changeStep" />
                <StepFour v-if="stepNumber === 4" type="assured" v-on:click="changeStep" />
                <StepFive v-if="stepNumber === 5" type="assured" v-on:click="changeStep" />
                <StepSix v-if="stepNumber === 6" type="assured" v-on:click="changeStep" />
            </div>
            <div class="clearfix"></div>
        </section>
        <!-- CONTAIN_END -->

    </div>
</template>

<script>
import StepOne from '@/components/declaration/StepOne.vue';
import StepTwo from '@/components/declaration/StepTwo.vue';
import StepThree from '@/components/declaration/StepThree.vue';
import StepFour from '@/components/declaration/StepFour.vue';
import StepFive from '@/components/declaration/StepFive.vue';
import StepSix from '@/components/declaration/StepSix.vue';
import {mapActions} from 'vuex';

export default {
  name: 'Declaration',
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix
  },
  data() {
      return {
      stepNumber: 1,
      showLoader: false,
    }
  },
  mounted() {
    const contractNumber = localStorage.getItem('contract_number');
    this.findContract(contractNumber);
  },
  methods: {
    ...mapActions('createSinistre', ['createSinister']),
    ...mapActions('contract', ['findContract']), 
    changeStep(step) {
        if (this.stepNumber === 6) {
            return;
        }

        switch (step.action) {
            case ('StepOne'):
                this.stepNumber = 2;
                break;
            case ('StepTwo'):
                this.stepNumber = 2;
                break;
            case ('StepThree'):
                this.stepNumber = 3;
                break;
            case ('StepFour'):
                this.stepNumber = 4;
                break;
            case ('StepFive'):
                this.stepNumber = 5;
                break;
            case ('End'):
                this.showLoader = true;
                this.createSinister()
                    .then(() => {
                        this.stepNumber = 6;
                    }
                    ).catch((e) => {
                        this.changeStep('StepFive');
                        console.error(e);
                    })
                    .finally(() => {
                        this.showLoader = false;
                    });
                break;
      }
    },
    backStep() {
        if (this.stepNumber === 6) {
            return
        }

        if (this.stepNumber === 5) {
            this.changeStep({ action: 'StepFour' });
        } else if (this.stepNumber === 4) {
            this.changeStep({ action: 'StepThree' });
        } else if (this.stepNumber === 3) {
            this.changeStep({ action: 'StepTwo' });
        } else if (this.stepNumber === 2) {
            this.changeStep({ action: 'StepOne' });
        } else if (this.stepNumber === 1) {
            this.$router.push('/assure')
        }
        },
    },
    beforeRouteLeave(to, from, next) {
        localStorage.removeItem('contract');
        localStorage.removeItem('contract_number');
        next();
    }
}
</script>
<style scoped>
.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>