<template>
    <div class="declaring-block-main-aedecp optiscroll stepFive">
        <div class="declaring-block-in-aedecp">
            <div class="common-title-main-aehp common-title-left-aehp">
                <h2>Signature</h2>
            </div>
            <div class="declaring-form-main-aedecp signature-form-main-aedecp">
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="defa-form-field-check-aedecp custom-control custom-checkbox">
                                <input id="contact_check1" v-model="sign.check" :value="true"
                                    class="custom-control-input" type="checkbox">
                                <label class="custom-control-label" for="contact_check1">J'accepte que les informations
                                    me concernant
                                    soient exploitées dans le cadre de la demande de contact et de la relation
                                    commerciale qui en
                                    découle.</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">Fait à</div>
                            <div class="defa-form-field-Tbox-aedecp">
                                <input v-model="sign.made_in" placeholder="" type="text"
                                    @change="(e) => remove(e.target)">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">Date</div>
                            <div
                                class="defa-form-field-Tbox-aedecp input-group defa-form-Dtpicker-aedecp date form_date">
                                <input :value="formatDate(sign.date)" class="cal-size datepicker_hp4" type="text"
                                    @change="(e) => remove(e.target)" @blur="(e) => onDateBlur(e, 'date')">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-note-text-aedecp default-note-text2-aedecp">
                    <p>*Vous pouvez en obtenir la communication ainsi que leur éventuelle rectification auprès de :
                        AGEMI –
                        Service sinistres – 11 rue de Rochechouart, 75009 PARIS</p>
                </div>
            </div>
            <div class="default-form-btn-bar-aedecp">
                <div class="default-form-btn-aedecp">
                    <a href="#" @click="previousStep" class="btn default-btn-aehp default-Bborder-btn-aehp">Etape
                        précédente</a>
                </div>
                <div class="default-form-btn-aedecp">
                    <a href="javascript:void(0);" class="btn default-btn-aehp default-green-btn-aehp"
                        @click="validate">Valider la déclaration</a>
                </div>
            </div>
        </div>
    </div>

    <div class="IBAN-modal-main-aehp modal fade" id="Validate_IBAN" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <i class="fas fa-times"></i>
                    </button>
                    <div class="declaration-modal-main-aedecp">
                        <div class="declaration-text-aedecp">
                            <h2>Attention, la déclaration ne sera plus modifiable après enregistrement</h2>
                        </div>
                        <div class="declaration-btns-aedecp">
                            <a href="#" class="btn default-btnH40-aehp default-Gborder-redT-btn-aehp"
                                data-dismiss="modal">Annuler</a>
                            <a href="#" class="btn default-btnH40-aehp" @click="redirect">Confirmer</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import $ from 'jquery';
import moment from 'moment';

export default {
    name: 'StepFive',
    props: ['type'],
    data() {
        return {
            sign: {
                check: null,
                made_in: null,
                date: null,
            }
        }
    },
    computed: mapState({
        storeSign: state => state.createSinistre.sign
    }),
    beforeMount() {
        this.sign = { ...this.storeSign }
    },
    mounted() {
        $('.datepicker_hp4').datepicker({
            uiLibrary: 'bootstrap4',
            iconsLibrary: 'fontawesome',
            format: "dd/mm/yyyy",
            language: "fr"
        }).on('changeDate', (e) => this.sign.date = e.date);
    },
    unmounted() {
        $('.datepicker_hp4').datepicker('destroy');
    },
    methods: {
        ...mapActions('createSinistre', ['addSign']),
        previousStep() {
            this.addSign(this.sign);
            this.$emit("click", { action: "StepFour" });
        },
        validate() {
            if (this.type === 'assured') {
                let numberInput = 3;
                let numberGoodInput = 0;
                $(".stepFive input:text").each(function () {
                    if (!$(this).val()) {
                        $(this).addClass('errorInput');
                    } else {
                        numberGoodInput++
                        $(this).removeClass('errorInput');
                    }
                });
                if (this.sign.check) {
                    $('.custom-control-label').removeClass('errorColor')
                    numberGoodInput++;
                } else {
                    $('.custom-control-label').addClass('errorColor')
                }
                if (numberInput === numberGoodInput) {
                    $('#Validate_IBAN').modal('show')
                } else {
                    const firstErrorInput = document.querySelector('.errorInput');
                    const firstErrorColor = document.querySelector('.errorColor');
                    if (firstErrorInput) {
                        firstErrorInput.scrollIntoView({ behavior: 'smooth' });
                    } else if (firstErrorColor) {
                        firstErrorColor.scrollIntoView({ behavior: 'smooth' });
                    }
                }
            } else {
                $('#Validate_IBAN').modal('show')
            }
        },
        redirect() {
            $('#Validate_IBAN').modal('hide')
            this.addSign(this.sign);
            if (this.type === 'assured') {
                this.$emit('click', { action: 'End' });
            } else {
                this.$emit('click', { action: 'StepSixAdmin' });
            }
        },
        remove(e) {
            $(e).removeClass('errorInput');
        },
        formatDate(date) {
            if (!date || !moment(date).isValid()) {
                return '';
            }
            return moment(date).format('DD/MM/YYYY');
        },
        onDateBlur(e, field) {
            const date = moment(e.target.value, 'DD/MM/YYYY');
            if (!date.isValid()) {
                this.sign[field] = null;
            }
            else {
                this.sign[field] = date.toDate();
            }
        }
    }

}
</script>
<style scoped>
.errorInput {
    border: solid 2px #f03737;
}

.errorColor {
    color: #f03737;
}
</style>
