<template>
    <div class="declaring-block-main-aedecp optiscroll stepFour">
        <div class="declaring-block-in-aedecp">
            <div class="common-title-main-aehp common-title-left-aehp">
                <h2>Renseignements relatifs au sinistre</h2>
            </div>
            <div class="declaring-form-main-aedecp">
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">Date d’apparition des désordres</div>
                            <div class="defa-form-field-Tbox-aedecp defa-form-Dtpicker-aedecp">
                                <input :value="formatDate(renseignements_sinister.date)" placeholder="" type="text"
                                    @change="(e) => remove(e.target)" @blur="(e) => onDateBlur(e, 'date')"
                                    class="cal-size datepicker_hp3">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">Sont-ils apparus durant la période de parfait
                                achèvement
                                (année qui suit la réception)
                            </div>
                            <div class="defa-option-list-aedecp">
                                <div class="defa-option1-aedecp">
                                    <div class="custom-control custom-radio">
                                        <input id="default_option7"
                                            v-model="renseignements_sinister.perfect_achievement" :value="true"
                                            class="custom-control-input" name="completion-option1" type="radio">
                                        <label class="custom-control-label" for="default_option7">Oui</label>
                                    </div>
                                </div>
                                <div class="defa-option1-aedecp">
                                    <div class="custom-control custom-radio">
                                        <input id="default_option8"
                                            v-model="renseignements_sinister.perfect_achievement" :value="false"
                                            class="custom-control-input" name="completion-option1" type="radio">
                                        <label class="custom-control-label" for="default_option8">Non</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-file-upload-tit-aedecp">Si oui, joindre une copie de la mise en
                                demeure adressée
                                au constructeur (Impératif)
                            </div>
                            <DragAndDrop v-on:updateImages="updateImages" />
                        </div>
                    </div>
                </div>
                <div v-if="this.new" class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">Garanties visées</div>
                            <div class="defa-option-list-aedecp">
                                <div class="defa-option1-aedecp">
                                    <div class="custom-control custom-checkbox">
                                        <input id="covered_guarantees_option1" class="custom-control-input"
                                            name="completion-option1" type="checkbox" value="DO"
                                            v-on:change="addGarantees($event)">
                                        <label class="custom-control-label" for="covered_guarantees_option1">DO</label>
                                    </div>
                                </div>
                                <div class="defa-option1-aedecp">
                                    <div class="custom-control custom-checkbox">
                                        <input id="covered_guarantees_option2" class="custom-control-input"
                                            name="completion-option1" type="checkbox" value="RCD"
                                            v-on:change="addGarantees($event)">
                                        <label class="custom-control-label" for="covered_guarantees_option2">RCD</label>
                                    </div>
                                </div>
                                <div class="defa-option1-aedecp">
                                    <div class="custom-control custom-checkbox">
                                        <input id="covered_guarantees_option3" class="custom-control-input"
                                            name="completion-option1" type="checkbox" value="RCP"
                                            v-on:change="addGarantees($event)">
                                        <label class="custom-control-label" for="covered_guarantees_option3">RCP</label>
                                    </div>
                                </div>
                                <div class="defa-option1-aedecp">
                                    <div class="custom-control custom-checkbox">
                                        <input id="covered_guarantees_option4" class="custom-control-input"
                                            name="completion-option1" type="checkbox" value="TRC"
                                            v-on:change="addGarantees($event)">
                                        <label class="custom-control-label" for="covered_guarantees_option4">TRC</label>
                                    </div>
                                </div>
                                <div class="defa-option1-aedecp">
                                    <div class="custom-control custom-checkbox">
                                        <input id="covered_guarantees_option5" class="custom-control-input"
                                            name="completion-option1" type="checkbox" value="CNR"
                                            v-on:change="addGarantees($event)">
                                        <label class="custom-control-label" for="covered_guarantees_option5">CNR</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="default-form-field1-aedecp">
                    <div class="default-form-field1-in-aedecp">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                            <div class="default-form-field1-tit-aedecp">Nature des désordres <span>Cochez et renseignez
                                    la rubrique correspondante :</span>
                            </div>
                            <div class="Ndisorders-block-main-aedecp">
                                <div id="Fissures" class="accordion">
                                    <div class="card Ndisorders-box1-main-aedecp">
                                        <div id="Ndisorders_heading1" class="card-header">
                                            <a aria-controls="Fissures_collapse1" aria-expanded="false"
                                                class="collapsed" data-target="#Fissures_collapse1"
                                                data-toggle="collapse" href="#">
                                                Fissures
                                            </a>
                                        </div>
                                        <div id="Fissures_collapse1" aria-labelledby="Fissures_heading1"
                                            class="collapse" data-parent="#Fissures">
                                            <div class="card-body Ndisorders-info-box-aedecp">
                                                <div class="Ndisord-info-box-in-aedecp">
                                                    <div class="default-form-field1-aedecp">
                                                        <div class="default-form-field1-in-aedecp">
                                                            <div
                                                                class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                                                <div
                                                                    class="default-form-field1-tit-aedecp default-Ffield1-tit-gray-aedecp">
                                                                    Localisation
                                                                </div>
                                                                <div class="defa-form-field-Tbox-aedecp">
                                                                    <input
                                                                        v-model="renseignements_sinister.cracks.location"
                                                                        placeholder="" type="text">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="default-form-field1-aedecp">
                                                        <div class="default-form-field1-in-aedecp">
                                                            <div
                                                                class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                                                <div
                                                                    class="default-form-field1-tit-aedecp default-Ffield1-tit-gray-aedecp">
                                                                    Importance (Facultatif)
                                                                </div>
                                                                <div class="defa-form-field-Tbox-aedecp">
                                                                    <input
                                                                        v-model="renseignements_sinister.cracks.importance"
                                                                        placeholder="" type="text">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="default-form-field1-aedecp">
                                                        <div class="default-form-field1-in-aedecp">
                                                            <div
                                                                class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                                                <div
                                                                    class="default-form-field1-tit-aedecp default-Ffield1-tit-gray-aedecp">
                                                                    Engendrent-elles (Facultatif) :
                                                                </div>
                                                                <div class="default-sub-form-row1-aedecp">
                                                                    <div
                                                                        class="defa-Ffield1-col1-aedecp defa-Ffield-Wauto-aedecp">
                                                                        <div class="default-form-field1-Stit-aedecp">des
                                                                            infiltrations</div>
                                                                        <div class="defa-option-list-aedecp">
                                                                            <div class="defa-option1-aedecp">
                                                                                <div
                                                                                    class="custom-control custom-radio">
                                                                                    <input id="default_option9"
                                                                                        v-model="renseignements_sinister.cracks.infiltrations"
                                                                                        :value="true"
                                                                                        class="custom-control-input"
                                                                                        name="generate-option1"
                                                                                        type="radio">
                                                                                    <label class="custom-control-label"
                                                                                        for="default_option9">Oui</label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="defa-option1-aedecp">
                                                                                <div
                                                                                    class="custom-control custom-radio">
                                                                                    <input id="default_option10"
                                                                                        v-model="renseignements_sinister.cracks.infiltrations"
                                                                                        :value="false"
                                                                                        class="custom-control-input"
                                                                                        name="generate-option1"
                                                                                        type="radio">
                                                                                    <label class="custom-control-label"
                                                                                        for="default_option10">Non</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="defa-Ffield1-col1-aedecp defa-Ffield-Wauto-aedecp">
                                                                        <div class="default-form-field1-Stit-aedecp">un
                                                                            risque de chute de matériaux</div>
                                                                        <div class="defa-option-list-aedecp">
                                                                            <div class="defa-option1-aedecp">
                                                                                <div
                                                                                    class="custom-control custom-radio">
                                                                                    <input id="default_option11"
                                                                                        v-model="renseignements_sinister.cracks.falling_materials"
                                                                                        :value="true"
                                                                                        class="custom-control-input"
                                                                                        name="generate-option2"
                                                                                        type="radio">
                                                                                    <label class="custom-control-label"
                                                                                        for="default_option11">Oui</label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="defa-option1-aedecp">
                                                                                <div
                                                                                    class="custom-control custom-radio">
                                                                                    <input id="default_option12"
                                                                                        v-model="renseignements_sinister.cracks.falling_materials"
                                                                                        :value="false"
                                                                                        class="custom-control-input"
                                                                                        name="generate-option2"
                                                                                        type="radio">
                                                                                    <label class="custom-control-label"
                                                                                        for="default_option12">Non</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="Infiltrations" class="accordion">
                                    <div class="card Ndisorders-box1-main-aedecp">
                                        <div id="Infiltrations_heading2" class="card-header">
                                            <a aria-controls="Infiltrations_collapse2" aria-expanded="false"
                                                class="collapsed" data-target="#Infiltrations_collapse2"
                                                data-toggle="collapse" href="#">
                                                Infiltrations
                                            </a>
                                        </div>
                                        <div id="Infiltrations_collapse2" aria-labelledby="Infiltrations_heading2"
                                            class="collapse" data-parent="#Infiltrations">
                                            <div class="card-body Ndisorders-info-box-aedecp">
                                                <div class="Ndisord-info-box-in-aedecp">
                                                    <div class="default-form-field1-aedecp">
                                                        <div class="default-form-field1-in-aedecp">
                                                            <div
                                                                class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                                                <div
                                                                    class="default-form-field1-tit-aedecp default-Ffield1-tit-gray-aedecp">
                                                                    Localisation
                                                                </div>
                                                                <div class="defa-form-field-Tbox-aedecp">
                                                                    <input
                                                                        v-model="renseignements_sinister.infiltrations.location"
                                                                        placeholder="" type="text">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="default-form-field1-aedecp">
                                                        <div class="default-form-field1-in-aedecp">
                                                            <div
                                                                class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                                                <div
                                                                    class="default-form-field1-tit-aedecp default-Ffield1-tit-gray-aedecp">
                                                                    Importance (Facultatif)
                                                                </div>
                                                                <div class="defa-form-field-Tbox-aedecp">
                                                                    <input
                                                                        v-model="renseignements_sinister.infiltrations.importance"
                                                                        placeholder="" type="text">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="default-form-field1-aedecp">
                                                        <div class="default-form-field1-in-aedecp">
                                                            <div
                                                                class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                                                <div
                                                                    class="default-form-field1-tit-aedecp default-Ffield1-tit-gray-aedecp">
                                                                    L’origine
                                                                    est-elle (Facultatif) :
                                                                </div>
                                                                <div class="defa-option-list-aedecp">
                                                                    <div class="defa-option1-aedecp">
                                                                        <div class="custom-control custom-radio">
                                                                            <input id="default_option13"
                                                                                v-model="renseignements_sinister.infiltrations.origin"
                                                                                :value="true"
                                                                                class="custom-control-input"
                                                                                name="origin-option1" type="radio">
                                                                            <label class="custom-control-label"
                                                                                for="default_option13">Apparente</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="defa-option1-aedecp">
                                                                        <div class="custom-control custom-radio">
                                                                            <input id="default_option14"
                                                                                v-model="renseignements_sinister.infiltrations.origin"
                                                                                :value="false"
                                                                                class="custom-control-input"
                                                                                name="origin-option1" type="radio">
                                                                            <label class="custom-control-label"
                                                                                for="default_option14">Non
                                                                                accessible</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="other" class="accordion">
                                    <div class="card Ndisorders-box1-main-aedecp">
                                        <div id="Ndisorders_heading3" class="card-header">
                                            <a aria-controls="Ndisorders_collapse3" aria-expanded="false"
                                                class="collapsed" data-target="#Ndisorders_collapse3"
                                                data-toggle="collapse" href="#">
                                                Autre désordre
                                            </a>
                                        </div>
                                        <div id="Ndisorders_collapse3" aria-labelledby="Ndisorders_heading3"
                                            class="collapse" data-parent="#other">
                                            <div v-for="(items, key) in renseignements_sinister.other" :key="key"
                                                class="card-body Ndisorders-info-box-aedecp">{{ key }}>
                                                <div class="title">
                                                    <div
                                                        class="default-form-field1-tit-aedecp default-Ffield1-tit-gray-aedecp">
                                                        {{ key + 1 }}.
                                                    </div>
                                                    <div class="default-form-field1-tit-aedecp default-Ffield1-tit-gray-aedecp custom-add"
                                                        @click="deleteDisorder(key)">
                                                        <p class="btn-custom">X</p>
                                                    </div>
                                                </div>
                                                <div class="Ndisord-info-box-in-aedecp">
                                                    <div class="default-form-field1-aedecp">
                                                        <div class="default-form-field1-in-aedecp">
                                                            <div
                                                                class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                                                <div
                                                                    class="default-form-field1-tit-aedecp default-Ffield1-tit-gray-aedecp">
                                                                    Description (Facultatif)
                                                                </div>
                                                                <div class="defa-form-field-Tbox-aedecp">
                                                                    <textarea
                                                                        v-model="renseignements_sinister.other[key].description"
                                                                        cols="1" placeholder="" rows="1"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="default-form-field1-aedecp">
                                                        <div class="default-form-field1-in-aedecp">
                                                            <div
                                                                class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                                                <div
                                                                    class="default-form-field1-tit-aedecp default-Ffield1-tit-gray-aedecp">
                                                                    Localisation
                                                                </div>
                                                                <div class="defa-form-field-Tbox-aedecp">
                                                                    <input
                                                                        v-model="renseignements_sinister.other[key].location"
                                                                        placeholder="" type="text">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="default-form-field1-aedecp">
                                                        <div class="default-form-field1-in-aedecp">
                                                            <div
                                                                class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                                                <div
                                                                    class="default-form-field1-tit-aedecp default-Ffield1-tit-gray-aedecp">
                                                                    Importance (Facultatif)
                                                                </div>
                                                                <div class="defa-form-field-Tbox-aedecp">
                                                                    <input
                                                                        v-model="renseignements_sinister.other[key].importance"
                                                                        placeholder="" type="text">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="default-form-field1-aedecp">
                                                        <div class="default-form-field1-in-aedecp">
                                                            <div
                                                                class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
                                                                <div
                                                                    class="default-form-field1-tit-aedecp default-Ffield1-tit-gray-aedecp">
                                                                    Engendrent-elles (Facultatif) :
                                                                </div>
                                                                <div class="default-sub-form-row1-aedecp">
                                                                    <div
                                                                        class="defa-Ffield1-col1-aedecp defa-Ffield-Wauto-aedecp">
                                                                        <div class="default-form-field1-Stit-aedecp">des
                                                                            infiltrations</div>
                                                                        <div class="defa-option-list-aedecp">
                                                                            <div class="defa-option1-aedecp">
                                                                                <div
                                                                                    class="custom-control custom-radio">
                                                                                    <input
                                                                                        :id="'default_option9000' + key"
                                                                                        v-model="renseignements_sinister.other[key].infiltrations"
                                                                                        :name="'generate-option1' + key"
                                                                                        :value="true"
                                                                                        class="custom-control-input"
                                                                                        type="radio">
                                                                                    <label
                                                                                        :for="'default_option9000' + key"
                                                                                        class="custom-control-label">Oui</label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="defa-option1-aedecp">
                                                                                <div
                                                                                    class="custom-control custom-radio">
                                                                                    <input
                                                                                        :id="'default_option1000' + key"
                                                                                        v-model="renseignements_sinister.other[key].infiltrations"
                                                                                        :name="'generate-option1' + key"
                                                                                        :value="false"
                                                                                        class="custom-control-input"
                                                                                        type="radio">
                                                                                    <label
                                                                                        :for="'default_option1000' + key"
                                                                                        class="custom-control-label">Non</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="defa-Ffield1-col1-aedecp defa-Ffield-Wauto-aedecp">
                                                                        <div class="default-form-field1-Stit-aedecp">un
                                                                            risque de chute de matériaux</div>
                                                                        <div class="defa-option-list-aedecp">
                                                                            <div class="defa-option1-aedecp">
                                                                                <div
                                                                                    class="custom-control custom-radio">
                                                                                    <input
                                                                                        :id="'default_option1100' + key"
                                                                                        v-model="renseignements_sinister.other[key].falling_materials"
                                                                                        :name="'generate-option2' + key"
                                                                                        :value="true"
                                                                                        class="custom-control-input"
                                                                                        type="radio">
                                                                                    <label
                                                                                        :for="'default_option1100' + key"
                                                                                        class="custom-control-label">Oui</label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="defa-option1-aedecp">
                                                                                <div
                                                                                    class="custom-control custom-radio">
                                                                                    <input
                                                                                        :id="'default_option1200' + key"
                                                                                        v-model="renseignements_sinister.other[key].falling_materials"
                                                                                        :name="'generate-option2' + key"
                                                                                        :value="false"
                                                                                        class="custom-control-input"
                                                                                        type="radio">
                                                                                    <label
                                                                                        :for="'default_option1200' + key"
                                                                                        class="custom-control-label">Non</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="contentAdd">
                                                <div class="default-form-field1-tit-aedecp default-Ffield1-tit-gray-aedecp custom-add"
                                                    @click="addDisorder">
                                                    <p class="btn-custom">+</p> Ajouter un désordre
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="disordersCheck" class="danger-block-main-aedecp">
                <p>Il faut renseigner au moins un sinistre pour pouvoir poursuivre.</p>
            </div>
            <div class="warning-block-main-aedecp">
                <p>Pour nous permettre d’instruire plus rapidement votre sinistre, nous vous recommandons de joindre
                    quelques
                    photos des désordres et si possible un devis détaillé à votre déclaration.</p>
            </div>
            <div class="default-form-btn-bar-aedecp">
                <div class="default-form-btn-aedecp">
                    <a href="#" @click="previousStep" class="btn default-btn-aehp default-Bborder-btn-aehp">Etape
                        précédente</a>
                </div>
                <div class="default-form-btn-aedecp">
                    <a class="btn default-btn-aehp" href="#" v-on:click="nextStep">Continuer</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import DragAndDrop from '@/components/DragAndDrop/DragAndDrop.vue';
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';

export default {
    name: 'StepFour',
    components: {
        DragAndDrop
    },
    props: ['new', 'type'],
    data() {
        return {
            renseignements_sinister: {
                date: null,
                perfect_achievement: true,
                images: [],
                cracks: {
                    location: null,
                    importance: null,
                    infiltrations: null,
                    falling_materials: null
                },
                infiltrations: {
                    location: null,
                    importance: null,
                    origin: null,
                },
                other: [
                    {
                        description: null,
                        location: null,
                        importance: null,
                        infiltrations: null,
                        falling_materials: null
                    },
                ],
                garantees: []
            },
            disordersCheck: false
        }
    },
    computed: mapState({
        storeRenseignementsSinister: state => state.createSinistre.renseignements_sinister,
    }),
    beforeMount() {
        this.renseignements_sinister = { ...this.storeRenseignementsSinister }
    },
    mounted() {
        $('.datepicker_hp3').datepicker({
            uiLibrary: 'bootstrap4',
            iconsLibrary: 'fontawesome',
            format: "dd/mm/yyyy",
            language: "fr"
        }).on('changeDate', (e) => this.renseignements_sinister.date = e.date);
    },
    unmounted() {
        $('.datepicker_hp3').datepicker('destroy');
    },
    methods: {
        ...mapActions('createSinistre', ['addSinisterDetails']),
        updateImages(props) {
            this.renseignements_sinister.images = props.images;
        },
        previousStep() {
            this.addSinisterDetails(this.renseignements_sinister);
            this.$emit("click", { action: "StepThree" });
        },
        nextStep() {
            console.log(this);
            if (this.type === 'assured') {
                let numberInput = 3;
                let numberGoodInputFour = 0;
                $(".stepFour input.cal-size").each(function () {
                    if (!$(this).val()) {
                        $(this).addClass('errorInput');
                    } else {
                        numberGoodInputFour++
                        $(this).removeClass('errorInput');
                    }
                });

                if (this.renseignements_sinister.perfect_achievement && this.renseignements_sinister.images.length === 0) {
                    $('.default-form-file-upload-tit-aedecp').addClass('errorColor');
                } else {
                    $('.default-form-file-upload-tit-aedecp').removeClass('errorColor');
                    numberGoodInputFour++;
                }

                if (this.renseignements_sinister.cracks.location || this.renseignements_sinister.infiltrations.location || this.renseignements_sinister.other[0].location) {
                    numberGoodInputFour++;
                    this.disordersCheck = false;
                } else {
                    this.disordersCheck = true;
                }

                if (numberInput === numberGoodInputFour) {
                    this.addSinisterDetails(this.renseignements_sinister);
                    this.new ? this.$emit('click', { action: 'End' }) : this.$emit('click', { action: 'StepFive' });
                } else {
                    const firstErrorInput = document.querySelector('.errorInput');
                    const firstErrorColor = document.querySelector('.errorColor');
                    if (firstErrorInput) {
                        firstErrorInput.scrollIntoView({ behavior: 'smooth' });
                    } else if (firstErrorColor) {
                        firstErrorColor.scrollIntoView({ behavior: 'smooth' });
                    }
                }
                console.log(numberInput)
                console.log('numberGoodInputFour', numberGoodInputFour)
            } else {
                this.addSinisterDetails(this.renseignements_sinister);
                this.new ? this.$emit('click', { action: 'End' }) : this.$emit('click', { action: 'StepFive' });
            }
        },
        addGarantees(e) {
            const element = this.renseignements_sinister.garantees.includes(e.target.value);
            // element ?  this.renseignements_sinister.garantees.push(e.target.value) :  this.renseignements_sinister.garantees.filter((c) => { return c !== e.target.value });
            if (!element) {
                console.log('push')
                this.renseignements_sinister.garantees.push(e.target.value);
            } else {
                _.remove(this.renseignements_sinister.garantees, function (n) {
                    return n == e.target.value
                })
            }
            console.log(this.renseignements_sinister.garantees);
        },
        addDisorder() {
            this.renseignements_sinister.other.push({
                description: null,
                location: null,
                importance: null,
                infiltrations: null,
                falling_materials: null
            })
        },
        deleteDisorder(key) {
            this.renseignements_sinister.other.splice(key, 1)
        },
        remove(e) {
            $(e).removeClass('errorInput');
        },
        formatDate(date) {
            if (!date || !moment(date).isValid()) {
                return '';
            }
            return moment(date).format('DD/MM/YYYY');
        },
        onDateBlur(e, field) {
            const date = moment(e.target.value, 'DD/MM/YYYY');
            if (!date.isValid()) {
                this.renseignements_sinister[field] = null;
            }
            else {
                this.renseignements_sinister[field] = date.toDate();
            }
        }
  }
}
</script>
<style scoped>
.contentAdd {
  background: #FAFBFF;
  padding: 5px;
}

.btn-custom {
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00B8E4;
  margin-right: 10px;
}

.custom-add:hover .btn-custom {
  background: white;
  color: #00B8E4;
  border: solid 1px #00B8E4
}

.custom-add {
  cursor: pointer;
  display: flex;
}

.title {
  display: flex;
  justify-content: space-between;
}

.errorInput {
  border: solid 2px #f03737;
}

.errorColor {
  color: #f03737
}

.danger-block-main-aedecp {
  border: solid 1px #f03737;
  background: #FBF2F2;
  margin: 0px 0px 16px;
  padding: 15px 48px 15px;
  text-align: center;
}

.danger-block-main-aedecp p {
  font-size: 13px;
  line-height: 19px;
  color: #f03737;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
}
</style>
