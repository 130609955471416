<template>
    <div class="col-lg-6 col-md-6 col-sm-12 col-12 connexion-right-aelp">
        <div class="connexion-right-in-aelp">
            <div class="connexion-box-main-aelp">
                <div class="common-title-main-aehp common-title-center-aehp">
                    <h2>Espace Assuré</h2>
                </div>
                <div class="connexion-options-aelp">
                    <div class="connexion-option1-aelp">
                        <a v-on:click="redirect('declare')" class="btn default-btn-aehp">Déclarer un sinistre</a>
                    </div>
                    <div class="connexion-or-text-aelp">ou</div>
                    <div class="connexion-option1-aelp">
                        <a v-on:click="redirect('find')" class="btn default-btn-aehp">Consulter un sinistre en cours</a>
                    </div>
                    <div class="connexion-back-link-aelp">
                        <a href="#" class="default-link-aelp"><i class="fa-regular fa-arrow-left-long"></i>Retour au site</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Choice',
    methods: {
        redirect : function(action){
            this.$emit('click', {action});
        }
    }
}
</script>
