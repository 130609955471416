<template>
<section id="contain">
    <div class="login-block-main-lp">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12 login-block-in-lp">
                    <div class="login-left-box-lp">
                        <div class="login-box-main-lp">
                            <div class="login-box-logo-lp"><a href="#"><img src="~@/assets/images/logo_main_hp.svg" alt=""></a></div>
                            <div class="login-info-main-lp">
                                <div class="login-info-in-lp">
                                    <div class="login-info-tit-lp">
                                        <h2>Connexion</h2>
                                    </div>
                                    <div class="login-form-lp">
                                        <div class="login-field1-lp"> <!-- "login-field-correct-lp" , "login-field-error-lp" -->
                                            <div class="login-field1-tit-lp">Adresse email</div>
                                            <div class="login-field1-tbox-lp"><input v-model="email" type="text" placeholder="adresse email" ></div>
                                        </div>
                                        <div class="login-field1-lp login-field2-lp">
                                            <div class="login-field1-tit-lp">Mot de passe</div>
                                            <div class="login-field1-tbox-lp login-field2-tbox-lp">
                                                <!-- <input v-model="password" type="password" placeholder="mot de passe" > -->
                                                <input v-model="password" id="password-field" type="password" placeholder="mot de passe">
                                            </div>
                                            
                                        </div>
                                        <div class="forgot-pass-link-lp">
                                            <!-- <a href="#">Mot de passe oublié ?</a> -->
                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="login-check-field1-lp">
                                            <div class="defa-form-field-check-aedecp custom-control custom-checkbox">
                                                <input v-model="stayConnected" :value="true" type="checkbox" class="custom-control-input" id="contact_check1">
                                                <label class="custom-control-label" for="contact_check1">Je souhaite rester connecté</label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="login-btn-lp"><a href="#" v-on:click="connexion" class="btn default-btn-aehp">Se connecter</a></div>
                                        <div class="error-box-main-lp" v-if="error">
                                            <div class="error-text-lp"><i class="far fa-times-circle"></i>Identifiant inexistant</div>
                                            <div class="error-reset-lp"><a href="#">Réessayer</a></div>
                                            <div class="error-box-close-lp"><a href="#"><i class="fal fa-times"></i></a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="login-right-box-lp"></div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</section>
</template>

<script>
export default {
    name: 'Login',
    data(){
        return{
            email : 'john@test.fr',
            password : 'root',
            stayConnected : false,
            error : false
        }
    },
    methods: {
        connexion(){
            this.$store.dispatch('connexionAdministrator/connexion', {email : this.email, password : this.password, stayConnected : this.stayConnected })
            .then(() => {
            this.error = false;
            this.$router.push('/gestionnaire')
            })
            .catch(() => {
                this.error = true;
            })
        }
    },

}
</script>
<style scoped>
	.login-right-box-lp{
		background: url('~@/assets/images/lognin_banner_img_hp.png') no-repeat center bottom;
		background-size: cover ;
	}
</style>
